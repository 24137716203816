import { useUI } from '@/contexts/ui.context';
import { useSearch } from '@/hooks/search/useSearch';
import { unbxdClient } from '@/utils/unbxd';
import { UnbxdProduct, UnbxdResponse } from '@/utils/unbxd/types';
import { useSsrCompatible } from '@/utils/use-ssr-compatible';
import Scrollbar from '@components/common/scrollbar';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import { useWindowSize } from 'react-use';
import Highlight from './highlight';
import SearchProduct from './product';

interface SearchSuggestionsProps {
  className?: string;
}

export default function SearchSuggestions(props: SearchSuggestionsProps) {
  const [data, setData] = useState<UnbxdResponse>();
  const { value, focused, submit, setFocused, lastSearches, addLastSearch } = useSearch();
  const { t } = useTranslation('common');
  const { closeSidebar } = useUI();
  const { width } = useSsrCompatible(useWindowSize(), { width: 0, height: 0 });

  useEffect(() => {
    if (!focused) {
      return;
    }

    (async () => {
      const data = await unbxdClient.autosuggest({
        query: trim(value).length == 0 ? '*' : trim(value),
        'keywordSuggestions.count': 11,
        'popularProducts.count': 9,
      });

      setData(data?.response ?? undefined);
    })();
  }, [focused, value]);

  function onSubmit(item: UnbxdProduct) {
    if (!item.autosuggest || trim(item.autosuggest).length == 0) {
      return;
    }

    submit(item.autosuggest);
    setFocused(false);
    closeSidebar();

    if (item.doctype == 'IN_FIELD' || item.doctype == 'KEYWORD_SUGGESTION') {
      unbxdClient.pushEvent({
        event: 'AutosuggestSearchTrigger',
        AutosuggestSearchPayload: {
          query: item.autosuggest,
          autosuggestParams: {
            autosuggest_type: item.doctype,
            autosuggest_suggestion: item.autosuggest,
            field_name: '',
            field_value: '',
            src_field: '',
            internal_query: trim(value),
          },
        },
      });
    }
  }

  if (
    !focused ||
    ((!lastSearches || lastSearches.length == 0 || trim(value).length != 0) && (!data || isEmpty(data.products)))
  ) {
    return <></>;
  }

  const hasLastSearches = trim(value).length == 0 && lastSearches && lastSearches.length > 0;

  const filteredProducts = data?.products.filter((product) => product.doctype == 'POPULAR_PRODUCTS' && product.title);

  return (
    <div
      className={classNames(
        'z-10 flex w-full flex-grow flex-col overflow-hidden border border-gray-200 bg-white shadow-navigation lg:absolute lg:max-h-80vh lg:w-[calc(100%-6rem)]',
        props.className,
      )}
    >
      <Scrollbar>
        <div className="justify-between lg:flex">
          <div
            className={classNames('space-y-4 p-6 pb-0 lg:p-6', {
              'md:w-1/2 xl:w-1/4': !isEmpty(filteredProducts),
            })}
          >
            {hasLastSearches && (
              <div>
                <div className="mb-3 xl:whitespace-nowrap">{t('text-last-searches')}</div>
                <div>
                  {lastSearches.map((item, idx) => (
                    <div
                      key={idx}
                      className="relative flex cursor-pointer items-center py-1 hover:underline"
                      onClick={() =>
                        onSubmit({
                          uniqueId: '',
                          autosuggest: item,
                        })
                      }
                    >
                      <FaHistory
                        className="mr-2 flex-shrink-0 text-gray-400"
                        size={16}
                      />
                      <div className="text-sm">
                        <Highlight
                          text={item as string}
                          search={[value]}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {data &&
              !isEmpty(
                data.products.filter(
                  (product) => product.doctype != 'POPULAR_PRODUCTS' && Number(product.result_unbxd_double) > 0,
                ),
              ) && (
                <div>
                  <div className="mb-3 xl:whitespace-nowrap">{t('text-suggested-searches')}</div>
                  <div>
                    {data.products
                      .filter(
                        (product) => product.doctype != 'POPULAR_PRODUCTS' && Number(product.result_unbxd_double) > 0,
                      )
                      .slice(0, width > 1024 ? (hasLastSearches ? 11 - (lastSearches?.length ?? 0) : undefined) : 5)
                      .map((item, idx) => (
                        <div
                          key={idx}
                          className="relative cursor-pointer py-1 hover:underline"
                          onClick={() => onSubmit(item)}
                        >
                          <div className="text-sm">
                            <Highlight
                              text={item.autosuggest as string}
                              search={[value]}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
          </div>
          {!isEmpty(filteredProducts) && (
            <div className="w-full border-l border-l-gray-150 p-6">
              <div className="mb-3">{t('text-results')}</div>
              <div className="mb-3 flex flex-wrap">
                {filteredProducts?.map((item, idx) => (
                  <SearchProduct
                    key={idx}
                    item={item}
                    onClick={() => {
                      addLastSearch(value);
                      closeSidebar();
                      unbxdClient.pushEvent({
                        event: 'AutosuggestClickTrigger',
                        AutosuggestClickPayload: {
                          autosuggestParams: {
                            autosuggest_type: 'POPULAR_PRODUCTS',
                            internal_query: trim(value),
                            pid: item.uniqueId,
                            unbxdprank: (idx + 1).toString(),
                          },
                        },
                      });
                      unbxdClient.pushEvent({
                        event: 'AutosuggestSearchTrigger',
                        AutosuggestSearchPayload: {
                          query: trim(value),
                          autosuggestParams: {
                            autosuggest_type: 'POPULAR_PRODUCTS',
                            internal_query: trim(value),
                            pid: item.uniqueId,
                            unbxdprank: (idx + 1).toString(),
                          },
                        },
                      });
                    }}
                  />
                ))}
              </div>
              <div
                className="cursor-pointer hover:underline"
                onClick={() => submit(trim(value).length == 0 ? '*' : undefined)}
              >
                {t('text-view-all-results')}
              </div>
            </div>
          )}
        </div>
      </Scrollbar>
    </div>
  );
}
