import { useCart } from '@/hooks/cart/useCart';
import CartIcon from '@components/icons/cart-icon';
import classNames from 'classnames';
import Link from '../ui/link';

interface CartButtonPropbs {
  className?: string;
}

export default function CartButton(props: CartButtonPropbs) {
  const { cart } = useCart();

  const isEmpty = (cart?.total_quantity ?? 0) < 1;

  return (
    <Link
      href={'/cart'}
      className={props.className}
    >
      <button
        className="relative flex h-auto flex-shrink-0 transform items-center justify-center text-icon hover:text-action focus:outline-none"
        aria-label="cart-button"
      >
        <CartIcon />
        {!isEmpty && (
          <span
            className={classNames(
              'cart-counter-badge absolute -top-2.5 -right-3 flex items-center justify-center rounded-full bg-gf-lightblue font-bold text-white -end-2.5 xl:-top-3 xl:-end-3',
            )}
          >
            {cart?.total_quantity ?? 0}
          </span>
        )}
      </button>
    </Link>
  );
}
