import { useStore } from '@/hooks/store/useStore';
import CookieBar from '@components/common/cookie-bar';
import Footer from '@components/layout/footer/footer';
import Header from '@components/layout/header/header';
import TrustedShop from '@components/common/trustedShopWrapper';
import CrispChatBox from '@components/common/crispChatWrapper';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { ComponentProps, useEffect } from 'react';

interface LayoutProps extends React.PropsWithChildren {
  headerProps?: ComponentProps<typeof Header>;
  footer?: boolean;
}

export default function Layout(props: LayoutProps) {
  const store = useStore();
  const footer = props.footer ?? true;

  return (
    <div className="flex min-h-screen flex-col">
      <NextSeo
        additionalMetaTags={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
        ]}
        description={store.default_description}
        canonical={store.secure_base_url}
      />
      <Head>
        <title>{store.default_title}</title>
      </Head>
      <CookieBar />
      <Header {...props.headerProps} />
      <main
        className="relative flex-grow"
        style={{
          minHeight: '-webkit-fill-available',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {props.children}
      </main>
      {footer && <Footer />}
      <CrispChatBox />
      <TrustedShop />
    </div>
  );
}
