import { useRouter } from 'next/router';
import { PropsWithChildren, createContext, useCallback, useEffect, useRef, useState } from 'react';
import { ProductListContextData } from './list';

interface MemoryData {
  requestId: ProductListContextData['requestId'];
  numberOfProducts: ProductListContextData['numberOfProducts'];
  products: ProductListContextData['products'];
  options: ProductListContextData['options'];
  facets: ProductListContextData['facets'];
}

export interface ProductListMemoryData {
  getMemoryData: (url: string) => MemoryData | undefined;
  setMemoryData: (data: MemoryData, url: string) => void;
}

export const ProductListMemoryContext = createContext<ProductListMemoryData>({
  getMemoryData: () => undefined,
  setMemoryData: () => {},
});

export function ProductListMemoryProvider(props: PropsWithChildren) {
  const [memoryData, setMemoryData] = useState<Record<string, MemoryData>>();

  const router = useRouter();
  const scrollPositions = useRef<{ [url: string]: number }>({});
  const isBack = useRef(false);

  useEffect(() => {
    router.beforePopState(() => {
      isBack.current = true;
      return true;
    });

    function onRouteChangeStart() {
      const url = router.asPath;
      scrollPositions.current[url] = window.scrollY;
    }

    function onRouteChangeComplete(url: string) {
      if (isBack.current && scrollPositions.current[url]) {
        window.scroll({
          top: scrollPositions.current[url],
          behavior: 'auto',
        });
      }

      isBack.current = false;
    }

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router]);

  return (
    <ProductListMemoryContext.Provider
      value={{
        getMemoryData: useCallback(
          (url) => {
            return memoryData?.[url];
          },
          [memoryData],
        ),
        setMemoryData: useCallback(
          (data, url) => {
            setMemoryData({
              [url]: data,
            });
          },
          [setMemoryData],
        ),
      }}
    >
      {props.children}
    </ProductListMemoryContext.Provider>
  );
}
