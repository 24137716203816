const env = process?.env || {};

const ISG_REVALIDATE_DEFAULT = Number(env.ISG_REVALIDATE_DEFAULT) || 600;

export const serverEnv = {
  ADMIN_SECRET: env.ADMIN_SECRET,

  GRAPHQL_CACHE_DEFAULT_TTL: Number(env.GRAPHQL_CACHE_DEFAULT_TTL) || 86400,
  GRAPHQL_CACHE_MAX_TTL: Number(env.GRAPHQL_CACHE_MAX_TTL) || undefined,

  ISG_REVALIDATE_DEFAULT,
  ISG_REVALIDATE_PAGE_INDEX: Number(env.ISG_REVALIDATE_PAGE_INDEX) || ISG_REVALIDATE_DEFAULT,
  ISG_REVALIDATE_PAGE_PRODUCT: Number(env.ISG_REVALIDATE_PAGE_PRODUCT) || ISG_REVALIDATE_DEFAULT,
  ISG_REVALIDATE_PAGE_404: Number(env.ISG_REVALIDATE_PAGE_404) || ISG_REVALIDATE_DEFAULT,
};
