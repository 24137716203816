import Cookies from 'js-cookie';
import { createContext, PropsWithChildren, useLayoutEffect, useState } from 'react';
import { dataLayerPush } from './dataLayer';

const COOKIE_NAME = 'cookiesConsent';
const COOKIE_EXPIRE = 365;

type CookieValue = undefined | 'accepted' | 'declined';

type GtmCookieConsentTypes = {
  ad_storage: 'granted' | 'denied';
  analytics_storage: 'granted' | 'denied';
  ad_user_data: 'granted' | 'denied';
  ad_personalization: 'granted' | 'denied';
};

export function sendConsent(type: 'default' | 'update', types: GtmCookieConsentTypes) {
  (function (...data) {
    /* eslint-disable-next-line prefer-rest-params */
    dataLayerPush(arguments);
  })('consent', type, types);

  dataLayerPush({
    event: 'consentUpdate',
  });
}

function updateConsent(value: CookieValue) {
  if (value == 'accepted') {
    sendConsent('update', {
      analytics_storage: 'granted',
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
    });
  }

  if (value == 'declined') {
    sendConsent('update', {
      analytics_storage: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
    });
  }
}

export interface CookiesConsentData {
  cookieValue: CookieValue;
  setCookieValue: (value: CookieValue) => void;
}

export const CookiesConsentContext = createContext<CookiesConsentData>({
  cookieValue: 'declined',
  setCookieValue: () => {},
});

export function CookiesConsentProvider(props: PropsWithChildren) {
  const [cookieValue, setCookieValue] = useState<CookieValue>('declined');

  useLayoutEffect(() => {
    const value = Cookies.get(COOKIE_NAME) as CookieValue;
    setCookieValue(value);
    updateConsent(value);
  }, []);

  return (
    <CookiesConsentContext.Provider
      value={{
        cookieValue,
        setCookieValue: (value) => {
          setCookieValue(value);
          updateConsent(value);
          Cookies.set(COOKIE_NAME, value as string, { expires: COOKIE_EXPIRE });
        },
      }}
    >
      {props.children}
    </CookiesConsentContext.Provider>
  );
}
