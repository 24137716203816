import { QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { useCustomer } from './useCustomer';

export function useCustomerQuery<Q, V extends { [index: string]: unknown }>(
  document: TypedDocumentNode<Q, V>,
  options: QueryHookOptions<Q, V> & {
    hydration?: boolean;
  } = {},
) {
  const { hydration, ...queryOptions } = options;
  const { customerToken } = useCustomer();

  queryOptions.skip = queryOptions?.skip || !customerToken;
  queryOptions.ssr = !!hydration;

  queryOptions.context = {
    headers: {
      Authorization: 'Bearer ' + customerToken,
      Store: process.env.NEXT_PUBLIC_STORE_CODE,
    },
  };

  const result = useQuery(document, queryOptions);

  return {
    ...result,
  };
}
