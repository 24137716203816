const MenuSearchIcon = ({color = 'currentColor', width = '17px', height = '18px', className = ''}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35.59 30"
      className={className}
    >
      <path
        fill={color}
        d="M31.33,22.49l-5.63-5.63c1.07-1.3,1.71-2.96,1.71-4.77c0-4.16-3.37-7.52-7.52-7.52s-7.52,3.37-7.52,7.52
		s3.37,7.52,7.52,7.52c1.71,0,3.27-0.57,4.53-1.53l5.66,5.66c0.17,0.17,0.4,0.26,0.63,0.26s0.45-0.09,0.63-0.26
		C31.68,23.39,31.68,22.83,31.33,22.49z"
        transform="translate(4, -2)"
      />
      <path
        fill={color}
        d="M11.19,6.43H1.09c-0.41,0-0.75-0.34-0.75-0.75s0.34-0.75,0.75-0.75h10.1c0.41,0,0.75,0.34,0.75,0.75S11.6,6.43,11.19,6.43z
		"
      />
      <path
        fill={color}
        d="M11.19,15.08H1.09c-0.41,0-0.75-0.34-0.75-0.75s0.34-0.75,0.75-0.75h10.1c0.41,0,0.75,0.34,0.75,0.75
		S11.6,15.08,11.19,15.08z"
      />
      <path
        fill={color}
        d="M11.19,10.76H1.09c-0.41,0-0.75-0.34-0.75-0.75s0.34-0.75,0.75-0.75h10.1c0.41,0,0.75,0.34,0.75,0.75
		S11.6,10.76,11.19,10.76z"
      />
    </svg>
  );
};

export default MenuSearchIcon;
