import { basePath } from '@/utils/stores';

export const IMAGE_DEFAULT_WIDTH = 1920;
export const IMAGE_DEFAULT_QUALITY = 90;

const deviceSizes = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];

interface OptimizeImageOptions {
  width?: number | string;
  quality?: number | string;
}

export default function getOptimizedImageUrl(src: string, options?: OptimizeImageOptions) {
  if (typeof window == 'undefined') {
    return src;
  }

  const url = new URL(basePath + '/_next/image', window.location.origin);
  const params = new URLSearchParams();

  let width = IMAGE_DEFAULT_WIDTH;

  if (options?.width) {
    for (const size of deviceSizes) {
      if (size >= Number(options.width)) {
        width = size;
        break;
      }
    }
  }

  params.set('url', src);
  params.set('w', '' + width);
  params.set('q', '' + (options?.quality ?? IMAGE_DEFAULT_QUALITY));

  url.search = params.toString();

  return url.href;
}
