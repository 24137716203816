import { IMAGE_DEFAULT_QUALITY, IMAGE_DEFAULT_WIDTH } from '@/utils/image';
import classNames from 'classnames';
import NextImage, { ImageProps } from 'next/future/image';
import { useState } from 'react';
import BoxLoader from '../ui/loaders/box-loader';

const ErrorPlaceholder = (props: any) => (
  <svg
    width={'100%'}
    height={'100%'}
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="300"
      height="300"
      fill="#F5F5F5"
    />
    <path
      d="M132.926 71C86.6523 73.9067 50 112.407 50 159.475C50 206.542 86.6523 245.042 132.926 248V71Z"
      fill="white"
    />
    <path
      d="M144.176 166.665V248C188.057 245.246 223.284 210.417 226.796 166.665H144.176Z"
      fill="white"
    />
    <path
      d="M144.176 71L227 155.395V71H144.176Z"
      fill="white"
    />
  </svg>
);

export default function Image(
  props: Omit<ImageProps, 'src'> & {
    src?: ImageProps['src'] | undefined | null;
    hasLoader?: boolean;
  },
) {
  const { src, width, height, quality, hasLoader, className, onLoadingComplete, ...rest } = props;

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      {src && (
        <NextImage
          src={src as ImageProps['src']}
          width={width || IMAGE_DEFAULT_WIDTH}
          height={height || IMAGE_DEFAULT_WIDTH}
          quality={quality || IMAGE_DEFAULT_QUALITY}
          onLoadingComplete={(img) => {
            onLoadingComplete?.(img);
            setLoaded(true);
          }}
          onError={() => {
            setLoaded(false);
            setError(true);
          }}
          className={classNames(className, {
            'pointer-events-none absolute opacity-0': hasLoader && !loaded,
            hidden: error,
          })}
          {...rest}
        />
      )}
      {src && hasLoader && !loaded && !error && <BoxLoader className={classNames(className)} />}
      {(!src || error) && <ErrorPlaceholder className={classNames(className)} />}
    </>
  );
}
