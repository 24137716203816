import { ProductInterface } from '.gql/graphql';
import { getAttributeValue } from '@/components/product/productPageDetail/attributes';
import { CartDetailFragment } from '@/contexts/cart.gql';
import { dataLayerPush } from '@/contexts/dataLayer';
import { getIdByUid } from '../string';
import { UnbxdProduct } from '../unbxd/types';
import { GA4 } from './events';

export const gaClient = new (class {
  pushEvent(event: GA4.Event) {
    dataLayerPush({ ecommerce: null });
    dataLayerPush(event);
  }
})();

export type GaProductInterface = Partial<Omit<ProductInterface, 'id'>>;

export function cartItemToGaItem(item: NonNullable<CartDetailFragment['items']>[number]) {
  const options: string[] = [];

  if (item?.__typename == 'ConfigurableCartItem') {
    item.configurable_options.map((option) => {
      option?.value_label && options.push(option?.value_label);
    });
  }

  if (item?.__typename == 'SimpleCartItem' || item?.__typename == 'ConfigurableCartItem') {
    item.customizable_options.map((option) => {
      option?.values[0]?.label && options.push(option.values[0].label);
    });
  }

  return productToGaItem(item?.product as GaProductInterface, undefined, options);
}

export function productToGaItem(
  parent: GaProductInterface | null | undefined,
  product?: GaProductInterface | null | undefined,
  options?: (string | null | undefined)[],
): GA4.Item {
  product = product || parent;

  const manufacturer = getAttributeValue(
    parent?.all_custom_attributes?.filter(
      (attribute) => attribute?.attribute_code == 'manufacturer' && getAttributeValue(attribute) !== null,
    )[0],
  ) as string | undefined;

  const categories: {
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
  } = {};

  if (parent?.categories) {
    let curCategory: NonNullable<typeof parent.categories>[number] | undefined;
    parent.categories?.map((category) => {
      if (!category?.level) {
        return;
      }

      if (!curCategory || Number(curCategory.level) < category.level) {
        curCategory = category;
      }
    });

    if (curCategory) {
      const breadcrumbs = [...(curCategory.breadcrumbs ? curCategory.breadcrumbs : [])];

      breadcrumbs?.push({
        category_uid: curCategory.uid,
        category_url_key: curCategory.url_key,
      });

      if (breadcrumbs?.[0]?.category_url_key) categories.item_category = breadcrumbs[0]?.category_url_key;
      if (breadcrumbs?.[1]?.category_url_key) categories.item_category2 = breadcrumbs[1]?.category_url_key;
      if (breadcrumbs?.[2]?.category_url_key) categories.item_category3 = breadcrumbs[2]?.category_url_key;
      if (breadcrumbs?.[3]?.category_url_key) categories.item_category4 = breadcrumbs[3]?.category_url_key;
      if (breadcrumbs?.[4]?.category_url_key) categories.item_category5 = breadcrumbs[4]?.category_url_key;
    }
  }

  return {
    item_id: getIdByUid(product?.uid as string),
    item_name: product?.name as string,
    item_brand: manufacturer,
    ...categories,
    price: product?.price_range?.minimum_price?.final_price.value as number,
    ...(options ? { item_variant: options.join('/') } : null),
  };
}

export function unbxdProductToGaItem(product: UnbxdProduct): GA4.Item {
  return {
    item_id: product.uniqueId,
    item_name: product.title as string,
    price: product.finalPrice,
  };
}
