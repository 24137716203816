const DesignMyHome = ({ color = 'black', backgroundColor = '#F7F7F7' }) => {
  return (
    <svg
      width="40"
      height="54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_700_3844)">
        <rect
          x="7"
          y="9"
          width="44"
          height="35"
          rx="10"
          fill={backgroundColor}
        />
        <path
          d="M18.9791 26.1297V23.0125H33.455V26.0884C34.2498 25.0077 34.7138 25.0386 35.971 25.0386V32.9791H16.4653V25.0386C17.8014 24.8494 18.0508 25.2666 18.9813 26.1297H18.9791Z"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.2034 34.2115V35.6434H18.4755V34.2115"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.9277 34.2115V35.6434H35.1999V34.2115"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.1279 15.9731V19.1199C28.7569 19.9062 30.3097 19.9099 31.7971 19.1199V15.9731C30.2978 15.1218 28.7499 15.0868 27.1279 15.9731Z"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.0129 29.6798H33.4591"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.4588 32.7207L33.4546 26.0887"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.9792 26.1296L18.9833 32.9847"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.7971 17.5162C31.7971 17.5162 37.4403 16.4578 37.5815 24.8545C37.6182 27.0315 37.5815 33.5646 37.5815 33.5646"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.1948 34.5832H39"
          stroke={color}
          stroke-width="0.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default DesignMyHome;
