/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n                      query SaferpayPaymentUrl($cartId: String!, $returnUrl: String!) {\n                        getSaferpayPaymentUrl(input: {cart_id: $cartId, return_url: $returnUrl}) {\n                          url\n                          error_name\n                        }\n                      }\n                    ": types.SaferpayPaymentUrlDocument,
    "\n        mutation CreateEmptyCart {\n          createEmptyCart\n        }\n      ": types.CreateEmptyCartDocument,
    "\n      query ErrorPage($identifier: String!) {\n        cmsPage(identifier: $identifier) {\n          identifier\n          content_heading\n          pagebuilder {\n            html\n            components\n          }\n        }\n      }\n    ": types.ErrorPageDocument,
    "\n          query ProductPage($urlKey: String!) {\n            products(filter: { url_key: { eq: $urlKey } }) {\n              items {\n                id: uid\n                uid\n              }\n            }\n          }\n        ": types.ProductPageDocument,
    "\n          query CategoryPage($urlKey: String!) {\n            categories(filters: { url_key: { eq: $urlKey } }) {\n              items {\n                id: uid\n                uid\n              }\n            }\n          }\n        ": types.CategoryPageDocument,
    "\n        query CmsPage($urlKey: String!) {\n          cmsPage(identifier: $urlKey) {\n            title\n            meta_title\n            meta_description\n            identifier\n            content_heading\n            pagebuilder {\n              html\n              components\n            }\n          }\n        }\n      ": types.CmsPageDocument,
    "\n      query HomePage($identifier: String!) {\n        cmsPage(identifier: $identifier) {\n          identifier\n          content_heading\n          pagebuilder {\n            html\n            components\n          }\n        }\n      }\n    ": types.HomePageDocument,
    "\n    query DummyInline {\n      country {\n        id\n      }\n    }\n  ": types.DummyInlineDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                      query SaferpayPaymentUrl($cartId: String!, $returnUrl: String!) {\n                        getSaferpayPaymentUrl(input: {cart_id: $cartId, return_url: $returnUrl}) {\n                          url\n                          error_name\n                        }\n                      }\n                    "): (typeof documents)["\n                      query SaferpayPaymentUrl($cartId: String!, $returnUrl: String!) {\n                        getSaferpayPaymentUrl(input: {cart_id: $cartId, return_url: $returnUrl}) {\n                          url\n                          error_name\n                        }\n                      }\n                    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation CreateEmptyCart {\n          createEmptyCart\n        }\n      "): (typeof documents)["\n        mutation CreateEmptyCart {\n          createEmptyCart\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query ErrorPage($identifier: String!) {\n        cmsPage(identifier: $identifier) {\n          identifier\n          content_heading\n          pagebuilder {\n            html\n            components\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ErrorPage($identifier: String!) {\n        cmsPage(identifier: $identifier) {\n          identifier\n          content_heading\n          pagebuilder {\n            html\n            components\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n          query ProductPage($urlKey: String!) {\n            products(filter: { url_key: { eq: $urlKey } }) {\n              items {\n                id: uid\n                uid\n              }\n            }\n          }\n        "): (typeof documents)["\n          query ProductPage($urlKey: String!) {\n            products(filter: { url_key: { eq: $urlKey } }) {\n              items {\n                id: uid\n                uid\n              }\n            }\n          }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n          query CategoryPage($urlKey: String!) {\n            categories(filters: { url_key: { eq: $urlKey } }) {\n              items {\n                id: uid\n                uid\n              }\n            }\n          }\n        "): (typeof documents)["\n          query CategoryPage($urlKey: String!) {\n            categories(filters: { url_key: { eq: $urlKey } }) {\n              items {\n                id: uid\n                uid\n              }\n            }\n          }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query CmsPage($urlKey: String!) {\n          cmsPage(identifier: $urlKey) {\n            title\n            meta_title\n            meta_description\n            identifier\n            content_heading\n            pagebuilder {\n              html\n              components\n            }\n          }\n        }\n      "): (typeof documents)["\n        query CmsPage($urlKey: String!) {\n          cmsPage(identifier: $urlKey) {\n            title\n            meta_title\n            meta_description\n            identifier\n            content_heading\n            pagebuilder {\n              html\n              components\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query HomePage($identifier: String!) {\n        cmsPage(identifier: $identifier) {\n          identifier\n          content_heading\n          pagebuilder {\n            html\n            components\n          }\n        }\n      }\n    "): (typeof documents)["\n      query HomePage($identifier: String!) {\n        cmsPage(identifier: $identifier) {\n          identifier\n          content_heading\n          pagebuilder {\n            html\n            components\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query DummyInline {\n      country {\n        id\n      }\n    }\n  "): (typeof documents)["\n    query DummyInline {\n      country {\n        id\n      }\n    }\n  "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;