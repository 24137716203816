export function getPercentDiscount(
  regularPrice: number | null | undefined,
  finalPrice: number | null | undefined,
): number {
  if (!regularPrice || !finalPrice) {
    return 0;
  }

  return Math.round(finalPrice < regularPrice ? 100 - (finalPrice * 100) / regularPrice : 0);
}

export function getDiscountByPercent(price: number | null | undefined, percentDiscount: number): number {
  if (!price) {
    return 0;
  }

  return price - (price / 100) * percentDiscount;
}
