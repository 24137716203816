import { getAdvanced } from '../../utils';
import { UnbxdHtmlWidget } from '../Unbxd/recommendations';

export default async function config(node: HTMLElement) {
  const unbxdWidgets = await getUnbxdWidgets(node);

  let html;
  if (node.dataset.decoded) {
    html = node.innerHTML;
    if (process.env.NODE_ENV !== 'production') {
      console.warn('PageBuilder HTML content was unescaped! This may be a Magento configuration error.');
    }
  } else {
    html = '<!doctype html><body>' + node.textContent;
  }

  return {
    html: html,
    ...getAdvanced(node),
    ...(unbxdWidgets.length > 0 ? { unbxdWidgets } : null),
  };
}

async function getUnbxdWidgets(node: HTMLElement) {
  const widgets: UnbxdHtmlWidget[] = [];

  let currentScriptData = null;

  const elements = node.getElementsByTagName('*');
  for (const element of elements) {
    if (element.tagName == 'SCRIPT') {
      const matches = /window\.unbxd_recommendation_pageInfo = ({.*})/gm.exec(element.innerHTML);
      if (matches && matches[1]) {
        try {
          const data = JSON.parse(matches[1]);
          currentScriptData = data;
        } catch (ignored) {
          continue;
        }
      }
    } else if (element.tagName == 'DIV' && element.className == 'unbxd-recommendation-marker-js') {
      if (currentScriptData) {
        // TODO: server cache recs
        /* const data = await unbxdClient.recommendations({
          pageType: currentScriptData['pageType'],
          ip: '0.0.0.0',
          uid: '1',
          widget: element.getAttribute('data-container')?.toUpperCase() as UnbxdRecommendationsRequestParams['widget'],
        }); */

        widgets.push({
          ...currentScriptData,
          /* data: {
            widgets: data.widgets,
          }, */
          container: element.getAttribute('data-container'),
        });
      }
    }
  }

  return widgets;
}
