import { UnbxdOptions } from './types';

export function getFiltersParams(filters: UnbxdOptions['filters']) {
  const preparedFilters: { field: string; value: string[] }[] = [];

  for (const key in filters) {
    if (key == 'sale') {
      continue;
    }

    preparedFilters.push({
      field: key,
      value: filters[key]?.map((value) => getPreparedValue(value)) ?? [],
    });
  }

  const returnFilters = preparedFilters.map((filter) =>
    filter.value.map((value) => filter.field + ':' + value).join(' OR '),
  );

  for (const key in filters) {
    if (key == 'sale') {
      returnFilters.push('oldPrice:' + getPreparedValue('1-999999'));
    }
  }

  if (returnFilters.length == 0) {
    return undefined;
  }

  return returnFilters;
}

function getPreparedValue(value: string) {
  if (/\d+-\d+/.test(value)) {
    const prices = value?.split('-');
    return '[' + prices[0] + ' TO ' + prices[1] + ']';
  }

  return JSON.stringify(value);
}
