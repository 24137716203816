import Image from '@/components/common/image';
import Money from '@/components/common/money';
import Link from '@/components/ui/link';
import { getProductRoute } from '@/utils/routes';
import classNames from 'classnames';
import { ProductsByUrlKeyQuery } from './products.gql';

interface ProductItemProps {
  item: NonNullable<NonNullable<ProductsByUrlKeyQuery['products']>['items']>[number];
}

export default function ProductItem(props: ProductItemProps) {
  const { item } = props;

  return (
    <Link
      href={getProductRoute(item?.url_key as string)}
      className={classNames(`group box-border flex flex-col items-start overflow-hidden pe-0 md:pb-1`)}
    >
      <div className={classNames('mb-3 flex w-full items-center justify-center pb-0 md:mb-3.5')}>
        <Image
          key={item?.image?.url}
          src={item?.image?.url}
          alt={`${item?.name}`}
          width={200}
          height={200}
          className="mx-2 mb-4 h-[200px] w-[200px] transform object-contain transition duration-150 ease-linear group-hover:scale-105"
        />
      </div>
      <div className="w-full overflow-hidden p-2 md:px-2.5 xl:px-4">
        <div className="mb-1 truncate text-sm font-semibold text-heading sm:text-base md:mb-1.5 md:text-sm lg:text-base xl:text-lg">
          {item?.name}
        </div>
        {item?.short_description?.html && (
          <div
            className="max-w-[250px] truncate text-xs leading-normal text-body lg:text-sm xl:leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: item?.short_description.html,
            }}
          ></div>
        )}
        <div
          className={`mt-1.5 text-sm font-semibold text-heading space-s-2 md:mt-2.5 md:text-base lg:text-xl 2xl:mt-3`}
        >
          <span className={`inline-block`}>
            <Money money={item?.price_range.minimum_price.final_price} />
          </span>
          {Number(item?.price_range.minimum_price.final_price.value) <
            Number(item?.price_range.minimum_price.regular_price.value) && (
            <del className={`font-normal text-gray-800 sm:text-base`}>
              <Money money={item?.price_range.minimum_price.regular_price} />
            </del>
          )}
        </div>
      </div>
    </Link>
  );
}
