import * as Types from '../../../.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export const ProductCustomAttributeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProductCustomAttribute"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CustomAttribute"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"attribute_code"}},{"kind":"Field","name":{"kind":"Name","value":"attribute_label"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProductBooleanAttribute"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"boolean_value"},"name":{"kind":"Name","value":"attribute_value"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProductFloatAttribute"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"float_value"},"name":{"kind":"Name","value":"attribute_value"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProductIntAttribute"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"int_value"},"name":{"kind":"Name","value":"attribute_value"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProductStringAttribute"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"string_value"},"name":{"kind":"Name","value":"attribute_value"}}]}}]}}]} as unknown as DocumentNode<ProductCustomAttributeFragment, unknown>;
export type ProductCustomAttribute_ProductBooleanAttribute_Fragment = { __typename?: 'ProductBooleanAttribute', attribute_code: string, attribute_label: string, boolean_value?: boolean | null };

export type ProductCustomAttribute_ProductFloatAttribute_Fragment = { __typename?: 'ProductFloatAttribute', attribute_code: string, attribute_label: string, float_value?: number | null };

export type ProductCustomAttribute_ProductIntAttribute_Fragment = { __typename?: 'ProductIntAttribute', attribute_code: string, attribute_label: string, int_value?: number | null };

export type ProductCustomAttribute_ProductStringAttribute_Fragment = { __typename?: 'ProductStringAttribute', attribute_code: string, attribute_label: string, string_value?: string | null };

export type ProductCustomAttributeFragment = ProductCustomAttribute_ProductBooleanAttribute_Fragment | ProductCustomAttribute_ProductFloatAttribute_Fragment | ProductCustomAttribute_ProductIntAttribute_Fragment | ProductCustomAttribute_ProductStringAttribute_Fragment;
