import React, { useEffect } from 'react';
import { useApp } from '@/hooks/app/useApp';

const CrispChatBox: React.FC = () => {
  const { crispChatBoxData } = useApp();

  useEffect(() => {
    if (!crispChatBoxData?.website_id) {
      console.log("crisp is off");
      return;
    }

    const crispChatId = crispChatBoxData.website_id;
    const crispScript = document.createElement("script");
    crispScript.src = "https://client.crisp.chat/l.js";
    crispScript.async = true;

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = crispChatId;

    document.head.appendChild(crispScript);

    return () => {
      document.head.removeChild(crispScript);
    };
  }, [crispChatBoxData]);

  return null;
};

export default CrispChatBox;
