import { serverEnv } from '@/server/env';
import {
  ApolloClient,
  ApolloLink,
  ApolloQueryResult,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  OperationVariables,
  QueryOptions,
} from '@apollo/client';
import { ConsoleLoggerLink } from '@freewall/apollo-console-logger';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { print } from 'graphql';
import { graphql } from '../../../.gql';
import fragments from '../../../.gql/fragments.json';

const links = [];

if (process.env.NODE_ENV === 'development') {
  links.push(new ConsoleLoggerLink());
}

if (typeof window === 'undefined') {
  const mesh = await import('.mesh').then(({ getBuiltMesh }) => getBuiltMesh());
  const MeshApolloLink = (await import('./meshLink')).MeshApolloLink;

  links.push(new MeshApolloLink(mesh));
} else {
  links.push(
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_LOCAL_ENDPOINT,
      useGETForQueries: true,
    }),
  );
}

const cache = new InMemoryCache({
  possibleTypes: fragments.possibleTypes,
});

if (typeof window !== 'undefined') {
  persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
}

if (serverEnv.GRAPHQL_CACHE_MAX_TTL !== undefined) {
  console.warn("GraphQL maximum cache's TTL is set by GRAPHQL_CACHE_MAX_TTL = " + serverEnv.GRAPHQL_CACHE_MAX_TTL);
}

export const apolloClient = new (class extends ApolloClient<NormalizedCacheObject> {
  async query<T, TVariables extends OperationVariables = OperationVariables>(
    options: QueryOptions<TVariables, T> & {
      ttl?: number;
    },
  ): Promise<ApolloQueryResult<T>> {
    if (typeof window !== 'undefined' || !options.ttl) {
      return super.query(options);
    }

    const { serverCache } = await import('@/server/cache');
    const crypto = await import('crypto');

    const key = crypto
      .createHash('md5')
      .update(options.query.definitions.map(print).join('\n'))
      .update(JSON.stringify(options.variables) ?? '')
      .digest()
      .toString('hex');

    return serverCache.getOrSet(
      key,
      async () => await super.query(options),
      serverEnv.GRAPHQL_CACHE_MAX_TTL !== undefined && options.ttl > serverEnv.GRAPHQL_CACHE_MAX_TTL
        ? serverEnv.GRAPHQL_CACHE_MAX_TTL
        : options.ttl,
    );
  }
})({
  link: ApolloLink.from(links),
  cache,
  ssrMode: typeof window === 'undefined',
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      context: {
        headers: {
          store: process.env.NEXT_PUBLIC_STORE_CODE,
        },
      },
    },
    mutate: {
      errorPolicy: 'all',
      context: {
        headers: {
          store: process.env.NEXT_PUBLIC_STORE_CODE,
        },
      },
    },
    watchQuery: {
      fetchPolicy: typeof window !== 'undefined' ? 'cache-and-network' : 'network-only',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
      context: {
        headers: {
          store: process.env.NEXT_PUBLIC_STORE_CODE,
        },
      },
    },
  },
});

export const gql = graphql;
