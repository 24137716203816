import classNames from 'classnames';
import { TbStar, TbStarFilled, TbStarHalfFilled } from 'react-icons/tb';

export const maxRating = 100;
export const starsCount = 5;

interface RatingDisplayProps {
  rating: number;
  className?: string;
}

export default function RatingDisplay(props: RatingDisplayProps) {
  const rating = props.rating / (maxRating / starsCount);

  return (
    <div className={classNames('flex space-x-1 text-[#fda41c]', props.className)}>
      {Array.from({
        length: parseInt((rating >= starsCount ? starsCount : rating) + ''),
      })?.map((_: any, id: number) => (
        <TbStarFilled key={`star-fill-icon-${id}`} />
      ))}
      {!!(rating < starsCount && parseFloat(String(rating)).toString().split('.')[1]) && <TbStarHalfFilled />}
      {Array.from({
        length:
          starsCount -
          (parseInt((rating >= starsCount ? starsCount : rating) + '') +
            Number(
              !!parseFloat(rating + '')
                .toString()
                .split('.')[1],
            )),
      })?.map((_: any, id: number) => (
        <TbStar key={`star-fill-icon-${id}`} />
      ))}
    </div>
  );
}
