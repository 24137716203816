import { removeDiacritics } from '@/utils/string';
import { findAll } from 'highlight-words-core';

interface HighlightProps {
  text: string;
  search: string[];
}

export default function Highlight(props: HighlightProps) {
  const chunks = findAll({
    autoEscape: true,
    textToHighlight: props.text,
    searchWords: props.search,
    sanitize: removeDiacritics,
  });

  const highligtText = chunks
    .map((chunk) => {
      const { end, highlight, start } = chunk;
      const text = props.text.substring(start, end);
      if (highlight) {
        return `<strong>${text}</strong>`;
      } else {
        return text;
      }
    })
    .join('');

  return <div dangerouslySetInnerHTML={{ __html: highligtText }}></div>;
}
