//@ts-check
import Carousel from '@/components/ui/carousel';
import classNames from 'classnames';
import { arrayOf, bool, number, object, oneOf, shape, string } from 'prop-types';
import { Children } from 'react';
import { SwiperOptions } from 'swiper';
import 'swiper/css';
import { useStyle } from '../../classify';
import { useMediaQuery } from '../../useMediaQuery';
import defaultClasses from './slider.module.css';

const Slider = (props) => {
  const classes = useStyle(defaultClasses, props.classes);

  const {
    minHeight,
    autoplay,
    autoplaySpeed,
    fade,
    infinite,
    showArrows,
    showDots,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    mediaQueries,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    cssClasses = [],
    children,
  } = props;

  const { styles: mediaQueryStyles } = useMediaQuery({ mediaQueries });

  const dynamicStyles = {
    minHeight,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };

  // Override classes on banner to ensure min height is respected
  Children.map(children, (child) => {
    if (child.props && child.props.data) {
      child.props.data.classes = {
        root: classes.bannerRoot,
        link: classes.bannerLink,
        wrapper: classes.bannerWrapper,
        posterOverlay: classes.bannerPosterOverlay,
      };
    }
    return child;
  });

  const cssClassesCopy = [...cssClasses];
  const sliderOptions = parseSliderOptions(cssClassesCopy);

  return (
    <div className="w-full overflow-hidden">
      <Carousel
        className={classNames('relative', classes.root, ...cssClassesCopy)}
        infinite={infinite}
        autoplay={
          autoplay && {
            delay: autoplaySpeed,
          }
        }
        initialSlide={sliderOptions.initialSlide ?? 0}
        slidesPerView={sliderOptions.slides ?? 2}
        breakpoints={sliderOptions.slidesBreakpoints}
        effect={fade ? 'fade' : 'slide'}
        arrows={showArrows}
        dots={showDots && { clickable: true }}
        style={{
          ...dynamicStyles,
          ...mediaQueryStyles,
          height: mediaQueryStyles['minHeight'] ? mediaQueryStyles['minHeight'] : minHeight,
        }}
      >
        {children}
      </Carousel>
    </div>
  );
};

Slider.propTypes = {
  classes: shape({
    root: string,
    bannerRoot: string,
    bannerLink: string,
    bannerWrapper: string,
    bannerPosterOverlay: string,
  }),
  appearance: oneOf(['default']),
  minHeight: string,
  autoplay: bool,
  autoplaySpeed: number,
  fade: bool,
  infinite: bool,
  showArrows: bool,
  showDots: bool,
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  mediaQueries: arrayOf(
    shape({
      media: string,
      style: object,
    }),
  ),
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  paddingLeft: string,
  cssClasses: arrayOf(string),
};

export default Slider;

interface SliderOptions {
  initialSlide?: number;
  slides?: number;
  slidesBreakpoints?: SwiperOptions['breakpoints'];
}

export function parseSliderOptions(cssClasses: string[]): SliderOptions {
  const options: SliderOptions = {};

  for (const idx in cssClasses) {
    const inital = parseDefaultInitial(cssClasses[idx] as string);
    if (inital) {
      options.initialSlide = Number(inital);
      delete cssClasses[idx];
      continue;
    }

    const breakpointSlides = parseBreadkpointSlides(cssClasses[idx] as string);
    if (breakpointSlides) {
      if (!options.slidesBreakpoints) {
        options.slidesBreakpoints = {};
      }

      options.slidesBreakpoints[breakpointSlides[0]] = { slidesPerView: breakpointSlides[1] };
      delete cssClasses[idx];
      continue;
    }

    const slides = parseDefaultSlides(cssClasses[idx] as string);
    if (slides) {
      options.slides = Number(slides);
      delete cssClasses[idx];
      continue;
    }
  }

  return options;
}

function parseDefaultInitial(cssClass: string): number | undefined {
  const matches = /option-initial-(\d+)/.exec(cssClass);
  if (matches && matches[1]) {
    return Number(matches[1]) - 1;
  }

  return undefined;
}

function parseDefaultSlides(cssClass: string): number | undefined {
  const matches = /option-slides-(\d+)/.exec(cssClass);
  if (matches && matches[1]) {
    return Number(matches[1]);
  }

  return undefined;
}

function parseBreadkpointSlides(cssClass: string): [number, number] | undefined {
  const matches = /option-slides-(\d+)-(\d+)/.exec(cssClass);
  if (matches && matches[1] && matches[2]) {
    return [Number(matches[1]), Number(matches[2])];
  }

  return undefined;
}
