import * as Types from '../../.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export const CustomerDetailFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CustomerDetail"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Customer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstname"}},{"kind":"Field","name":{"kind":"Name","value":"lastname"}},{"kind":"Field","name":{"kind":"Name","value":"gender"}},{"kind":"Field","name":{"kind":"Name","value":"addresses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstname"}},{"kind":"Field","name":{"kind":"Name","value":"lastname"}},{"kind":"Field","name":{"kind":"Name","value":"telephone"}},{"kind":"Field","name":{"kind":"Name","value":"street"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"postcode"}},{"kind":"Field","name":{"kind":"Name","value":"country_code"}},{"kind":"Field","name":{"kind":"Name","value":"default_billing"}},{"kind":"Field","name":{"kind":"Name","value":"default_shipping"}}]}}]}}]} as unknown as DocumentNode<CustomerDetailFragment, unknown>;
export const CustomerDetailDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CustomerDetail"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CustomerDetail"}}]}},{"kind":"Field","name":{"kind":"Name","value":"customerCart"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},...CustomerDetailFragmentDoc.definitions]} as unknown as DocumentNode<CustomerDetailQuery, CustomerDetailQueryVariables>;
export type CustomerDetailQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomerDetailQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', email?: string | null, firstname?: string | null, lastname?: string | null, gender?: number | null, id?: string | null, addresses?: Array<{ __typename?: 'CustomerAddress', id?: number | null, firstname?: string | null, lastname?: string | null, telephone?: string | null, street?: Array<string | null> | null, city?: string | null, postcode?: string | null, country_code?: Types.CountryCodeEnum | null, default_billing?: boolean | null, default_shipping?: boolean | null } | null> | null } | null, customerCart: { __typename?: 'Cart', id: string } };

export type CustomerDetailFragment = { __typename?: 'Customer', email?: string | null, firstname?: string | null, lastname?: string | null, gender?: number | null, id?: string | null, addresses?: Array<{ __typename?: 'CustomerAddress', id?: number | null, firstname?: string | null, lastname?: string | null, telephone?: string | null, street?: Array<string | null> | null, city?: string | null, postcode?: string | null, country_code?: Types.CountryCodeEnum | null, default_billing?: boolean | null, default_shipping?: boolean | null } | null> | null };
