import { basePath } from '@/utils/stores';

type SiteSettings = {
  name: string;
  description: string;
  author: {
    name: string;
    websiteUrl: string;
  };
  logo: {
    url: string;
    alt: string;
    href: string;
    width: number;
    height: number;
  };
  favicon: {
    url: string;
  };
};

export const getSiteSettings = (storeCode: string):SiteSettings  => {
  const assetsPath = basePath + '/assets/images/';

  const settings: { [key: string]: SiteSettings } = {
    default: {
      name: 'Goodform',
      description: '',
      author: {
        name: 'Goodform GmbH',
        websiteUrl: '/',
      },
      logo: {
        url: assetsPath + 'logo.svg',
        alt: 'Goodform',
        href: '/',
        width: 200,
        height: 30,
      },
      favicon: {
        url: assetsPath + 'favicon.svg',
      },
    },
    'de-de': {
      name: 'Goodform',
      description: '',
      author: {
        name: 'Goodform GmbH',
        websiteUrl: '/',
      },
      logo: {
        url: assetsPath + 'logo-' + storeCode + '.svg',
        alt: 'Goodform',
        href: '/',
        width: 200,
        height: 30,
      },
      favicon: {
        url: assetsPath + 'favicon-' + storeCode + '.svg',
      },
    },
    'ch-de': {
      name: 'Goodform',
      description: '',
      author: {
        name: 'Goodform AG',
        websiteUrl: '/',
      },
      logo: {
        url: assetsPath + 'logo-' + storeCode + '.svg',
        alt: 'Goodform',
        href: '/',
        width: 200,
        height: 30,
      },
      favicon: {
        url: assetsPath + 'favicon-' + storeCode + '.svg',
      },
    },
    'ch_fr': {
      name: 'Goodform',
      description: '',
      author: {
        name: 'Goodform AG',
        websiteUrl: '/',
      },
      logo: {
        url: assetsPath + 'logo-' + storeCode + '.svg',
        alt: 'Goodform',
        href: '/',
        width: 200,
        height: 30,
      },
      favicon: {
        url: assetsPath + 'favicon-' + storeCode + '.svg',
      },
    },
  };

  return settings[storeCode] || settings['default'] as SiteSettings;
};
