import Link from '@components/ui/link';
import { storeCode } from '@/utils/stores';
import { getSiteSettings } from '@settings/site-settings';
import cn from 'classnames';
import Image from '../common/image';

const Logo: React.FC<React.AnchorHTMLAttributes<{}>> = ({ className, ...props }) => {
  return (
    <Link
      href={getSiteSettings(storeCode).logo.href}
      className={cn('flex focus:outline-none', className)}
      {...props}
    >
      <Image
        src={getSiteSettings(storeCode).logo.url}
        alt={getSiteSettings(storeCode).logo.alt}
        height={getSiteSettings(storeCode).logo.height}
        width={getSiteSettings(storeCode).logo.width}
        loading="eager"
      />
    </Link>
  );
};

export default Logo;
