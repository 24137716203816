import { UnbxdProduct } from './types';

export function hasSpecialPrice(product: UnbxdProduct): boolean {
  if (product.specialFromDate && new Date(product.specialFromDate).getTime() > new Date().getTime()) {
    return false;
  }

  if (product.specialToDate && new Date(product.specialToDate).getTime() < new Date().getTime()) {
    return false;
  }

  return !!product.specialPrice;
}
