import CaretRightIcon from '@/components/icons/caret-right';
import Link from '@/components/ui/link';
import { useApp } from '@/hooks/app/useApp';
import { getCategoryRoute } from '@/utils/routes';
import { trpc } from '@/utils/trpc';
import classNames from 'classnames';
import { useState } from 'react';
import Submenu from './submenu';

let hoverTimeout = 0;

export default function Navigation() {
  const { categories, categoriesHash } = useApp();
  const [activeSubmenu, setActiveSubmenu] = useState<string>();
  const { data } = trpc.category.list.useQuery(categoriesHash, {
    trpc: {
      context: {
        skipBatch: true,
      },
    },
  });

  function onMouseOverLink(uid: string) {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      hoverTimeout = 0;
    }

    hoverTimeout = window.setTimeout(() => {
      hoverTimeout = 0;
      setActiveSubmenu(uid);
    }, 150);
  }

  return (
    <div className="mx-auto hidden max-w-[1920px] items-center justify-center lg:flex lg:h-16">
      <nav
        className="relative flex"
        onMouseLeave={() => {
          clearTimeout(hoverTimeout);
          hoverTimeout = 0;
          setActiveSubmenu(undefined);
        }}
      >
        {(data ?? categories)?.map((item, idx) => {
          return (
            <div key={item?.uid}>
              <Link
                href={getCategoryRoute(item?.url_key as string)}
                className={classNames(
                  'inline-flex items-center px-3 py-4 text-sm font-normal uppercase xl:px-4 xl:text-base',
                  { 'text-body': activeSubmenu != item?.uid },
                  { 'font-semibold text-action': activeSubmenu == item?.uid },
                  { 'text-gf-red': item?.url_key == 'sale' },
                )}
                onClick={() => setActiveSubmenu(undefined)}
                onMouseOver={() => onMouseOverLink(item?.uid as string)}
              >
                {item?.name}
                {!!Number(item?.children_count) && (
                  <span className="mt-1 flex w-4 justify-end text-[8px] xl:mt-0.5">
                    <CaretRightIcon
                      width="9px"
                      className={classNames('transform transition duration-300 ease-in-out', {
                        '-rotate-90': activeSubmenu == item?.uid,
                        'rotate-90': activeSubmenu != item?.uid,
                      })}
                    />
                  </span>
                )}
              </Link>

              {!!Number(item?.children_count) && (
                <Submenu
                  item={item}
                  active={activeSubmenu == item?.uid}
                  last={idx + 1 == (data ?? categories)?.length}
                  onLinkClick={() => setActiveSubmenu(undefined)}
                  onMouseOver={() => {
                    clearTimeout(hoverTimeout);
                    hoverTimeout = 0;
                  }}
                />
              )}
            </div>
          );
        })}
      </nav>
    </div>
  );
}
