import { useHydrated } from '@/hooks/utils/useHydrated';
import { ROUTES } from '@/utils/routes';

import { useCookiesConsent } from '@/hooks/cookiesConsent/useCookiesConsent';
import cn from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import Button from '../ui/button';
import Link from '../ui/link';

export default function CookieBar() {
  const { cookieValue, setCookieValue } = useCookiesConsent();
  const hydrated = useHydrated();
  const { t } = useTranslation('common');

  return (
    <div
      className={cn(
        'fixed bottom-0 z-30 flex w-full flex-col items-center justify-center border-t border-t-gray-300 bg-gray-200 p-6 md:flex-row md:p-10',
        {
          hidden: !hydrated || cookieValue,
        },
      )}
    >
      <div className="mb-6 text-left md:mb-0">
        <div className="mb:block mb-3.5 text-lg font-semibold leading-6">{t('text-cookies-title')}</div>
        <div className="text-sm">
          <Trans
            t={t}
            i18nKey={'text-cookies-description'}
            components={{
              1: (
                <Link
                  href={ROUTES.PRIVACY}
                  className="underline hover:text-action"
                />
              ),
            }}
          />
        </div>
      </div>
      <div className="w-full space-y-3 md:ml-10 md:w-auto md:space-x-2 md:space-y-0 md:whitespace-nowrap">
        <Button
          onClick={() => setCookieValue('accepted')}
          variant="main"
          className="w-full md:w-auto"
        >
          {t('text-accept')}
        </Button>
        <Button
          onClick={() => setCookieValue('declined')}
          variant="secondary"
          className="w-full border border-gray-400 bg-gray-300 md:w-auto"
        >
          {t('text-decline')}
        </Button>
      </div>
    </div>
  );
}
