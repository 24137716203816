import { useUI } from '@contexts/ui.context';
import dynamic from 'next/dynamic';
import Modal from './modal';
const LoginForm = dynamic(() => import('@components/auth/login-form'));
const SignUpForm = dynamic(() => import('@components/auth/sign-up-form'));
const ForgetPasswordForm = dynamic(() => import('@components/auth/forget-password-form'));
const ManagedModal: React.FC = () => {
  const { displayModal, closeModal, modalView } = useUI();
  return (
    <Modal
      open={displayModal}
      onClose={closeModal}
    >
      {modalView === 'LOGIN_VIEW' && <LoginForm />}
      {modalView === 'SIGN_UP_VIEW' && <SignUpForm />}
      {modalView === 'FORGET_PASSWORD' && <ForgetPasswordForm />}
    </Modal>
  );
};

export default ManagedModal;
