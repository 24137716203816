import { apolloClient } from '@/utils/apollo';
import { DefaultSeo } from '@components/common/default-seo';
import ManagedModal from '@components/common/modal/managed-modal';
import DefaultLayout from '@components/layout/layout';
import { ManagedUIContext } from '@contexts/ui.context';
import { AnimatePresence } from 'framer-motion';
import { appWithTranslation } from 'next-i18next';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import { ReactNode, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

// external
import 'react-toastify/dist/ReactToastify.css';
// base css file
import ErrorBoundary from '@/components/500/error-boundary';
import { AppProvider } from '@/contexts/app';
import { CartProvider } from '@/contexts/cart';
import { CookiesConsentProvider } from '@/contexts/cookiesConsent';
import { CustomerProvider } from '@/contexts/customer';
import { DataLayerProvider, dataLayerPush } from '@/contexts/dataLayer';
import { CustomAppProps } from '@/types/app';
import { trpc } from '@/utils/trpc';
import { ApolloProvider } from '@apollo/client';
import '@styles/cms-content.css';
import '@styles/custom-plugins.css';
import '@styles/nprogress.css';
import '@styles/rc-drawer.css';
import '@styles/scrollbar.css';
import '@styles/swiper-carousel.css';
import '@styles/tailwind.css';
import { getDirection } from '@utils/get-direction';
import { NextWebVitalsMetric } from 'next/app';
import { ProductListMemoryProvider } from '../contexts/product/listMemory';
const nextI18NextConfig = require('../../next-i18next.config');

function handleExitComplete() {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0 });
  }
}

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const CustomApp = ({ Component, pageProps: { appContextProps, ...pageProps } }: CustomAppProps) => {
  const router = useRouter();
  const dir = getDirection(router.locale);
  useEffect(() => {
    document.documentElement.dir = dir;
  }, [dir]);

  const getLayout =
    Component.getLayout === undefined
      ? (component: ReactNode) => <DefaultLayout>{component}</DefaultLayout>
      : Component.getLayout
      ? Component.getLayout
      : (component: ReactNode) => component;

  return (
    <AnimatePresence
      mode="wait"
      onExitComplete={handleExitComplete}
    >
      <ApolloProvider client={apolloClient}>
        <AppProvider props={appContextProps}>
          <ErrorBoundary clientErrorPage={appContextProps?.clientErrorPage}>
            <DataLayerProvider>
              <CookiesConsentProvider>
                <CustomerProvider>
                  <CartProvider>
                    <ProductListMemoryProvider>
                      <ManagedUIContext>
                        {getLayout(
                          <>
                            <DefaultSeo />
                            <Component
                              {...pageProps}
                              key={router.route}
                            />
                            <ToastContainer />
                          </>,
                        )}
                        <ManagedModal />
                      </ManagedUIContext>
                    </ProductListMemoryProvider>
                  </CartProvider>
                </CustomerProvider>
              </CookiesConsentProvider>
            </DataLayerProvider>
          </ErrorBoundary>
        </AppProvider>
      </ApolloProvider>
    </AnimatePresence>
  );
};

export default trpc.withTRPC(appWithTranslation(CustomApp, nextI18NextConfig));

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
  dataLayerPush({
    event: name,
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    event_label: id,
    non_interaction: true,
  });
}
