import type { DrawerProps } from 'rc-drawer';
// import './motion.css';

export const maskMotion: DrawerProps['maskMotion'] = {
  motionAppear: false,
  onAppearEnd: console.warn,
};

export const motion: DrawerProps['motion'] = (placement) => ({
  motionAppear: false,
});

const motionProps: Partial<DrawerProps> = {
  maskMotion,
  motion,
};

export default motionProps;
