import { useRouter } from 'next/router';

interface NumberProps {
  number: number | undefined;
  locale?: string;
  options?: Intl.NumberFormatOptions;
}

export function getNumberFormat(locale: string | undefined, options?: Intl.NumberFormatOptions) {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    ...options,
  });
}

export default function NumberFormat(props: NumberProps) {
  const router = useRouter();

  if (!props.number) {
    return <></>;
  }

  return <>{getNumberFormat(props.locale || router.locale, props.options).format(props.number)}</>;
}
