import Usps from '@/components/common/usps';
import PageBuilder from '@/components/pagebuilder/pageBuilder';
import Container from '@/components/ui/container';
import { trpc } from '@/utils/trpc';
import classNames from 'classnames';
import Copyright from './copyright';
import styles from './footer.module.css';

export default function Footer() {
  const { data } = trpc.cmsBlocks.get.useQuery({
    identifiers: ['footer'],
  });

  return (
    <footer className={classNames(styles.root, 'mt-9 border-t border-gray-300 md:mt-11 lg:mt-16 3xl:mt-20')}>
      <Usps className="pb-3" />
      {data?.[0] && (
        <Container className="!px-0 md:!px-0 2xl:!px-0">
          <PageBuilder
            pagebuilder={data[0]?.pagebuilder}
            noWrapper={true}
          />
        </Container>
      )}
      <Copyright />
    </footer>
  );
}
