import { dataLayerPush } from '@/contexts/dataLayer';
import axios from 'axios';
import Cookies from 'js-cookie';
import { setAxiosLogger } from '../axios/logger';
import { UnbxdEvent } from './events';
import { getFiltersParams } from './filters';
import { getSortingParam, unbxdDefaultSorting } from './sorting';
import {
  UnbxdAutosuggestOptions,
  UnbxdAutosuggestRequestParams,
  UnbxdBrowseOptions,
  UnbxdBrowseRequestParams,
  UnbxdOptions,
  UnbxdProduct,
  UnbxdRecommendationsOptions,
  UnbxdRecommendationsRequestParams,
  UnbxdRecommendationsResult,
  UnbxdRequestParams,
  UnbxdResult,
  UnbxdSearchOptions,
  UnbxdSearchRequestParams,
} from './types';
import { hasSpecialPrice } from './utils';

const UNBXD_USERID_COOKIE_NAME = 'unbxd.userId';

const productFields: (keyof UnbxdProduct)[] = [
  'uniqueId',
  'availability',
  'title',
  'imageUrl',
  'productUrl',
  'price',
  'oldPrice',
  'specialPrice',
  'specialFromDate',
  'specialToDate',
  'createdAt',
  'qty',
  'manageStock',
  'rating',
];

function transformProduct(product: UnbxdProduct): UnbxdProduct {
  return {
    uniqueId: product.uniqueId,
    availability: product.availability,
    title: product.title,
    //description: product.description,
    imageUrl: product.imageUrl ?? [],
    productUrl: product.productUrl ?? '',
    ...(hasSpecialPrice(product)
      ? { regularPrice: product.oldPrice ?? null, finalPrice: product.specialPrice }
      : { regularPrice: product.oldPrice ?? null, finalPrice: product.price }),
    createdAt: product.createdAt,
    qty: product.qty,
    manageStock: product.manageStock,
    rating: product.rating ?? 0,
  };
}

export const unbxdClient = new (class {
  async browse(options: UnbxdBrowseOptions): Promise<UnbxdResult> {
    const params: UnbxdBrowseRequestParams = {
      'p-id': options.categoryId,
      ...this._dedupeOptions(options),
      fields: productFields.join(','),
    };

    const {
      data: { response, facets, searchMetaData },
      headers: { 'unbxd-request-id': requestId },
    } = await httpClient?.get<UnbxdResult>('category', {
      params,
    }).catch((error) => {
      console.error('Error:', error);
      return { data: { response: null, facets: null, searchMetaData: null }, headers: { 'unbxd-request-id': '' } };
    });

    const products =
      response?.products.map((product): UnbxdProduct => {
        return transformProduct(product);
      }) ?? [];

    return {
      requestId,
      response: {
        numberOfProducts: response?.numberOfProducts ?? 0,
        start: response?.start ?? 0,
        products,
      },
      facets,
      searchMetaData,
    };
  }

  async search(options: UnbxdSearchOptions): Promise<UnbxdResult> {
    const params: UnbxdSearchRequestParams = {
      q: options.query,
      fallback: true,
      'fallback.method': 'spellcheck',
      ...this._dedupeOptions(options),
      fields: productFields.join(','),
    };

    const {
      data: { response, redirect, facets, searchMetaData, didYouMean },
      headers: { 'unbxd-request-id': requestId },
    } = await httpClient.get<UnbxdResult>('search', {
      params,
    });

    const products =
      response?.products.map((product): UnbxdProduct => {
        return transformProduct(product);
      }) ?? [];

    return {
      requestId,
      response: {
        numberOfProducts: response?.numberOfProducts ?? 0,
        start: response?.start ?? 0,
        products,
      },
      facets,
      searchMetaData,
      didYouMean,
      redirect,
    };
  }

  async autosuggest(options: UnbxdAutosuggestOptions): Promise<UnbxdResult> {
    const params: UnbxdAutosuggestRequestParams = {
      q: options.query,
      'popularProducts.count': options['popularProducts.count'] ?? 6,
      'keywordSuggestions.count': options['keywordSuggestions.count'] ?? 3,
      'popularProducts.fields': productFields.join(','),
      'popularProducts.filter': '-status:Disabled',
      ...this._dedupeOptions(options),
      fields: ['doctype', 'autosuggest', 'result_unbxd_double'].join(','),
    };

    const {
      data: { response, facets, searchMetaData },
      headers: { 'unbxd-request-id': requestId },
    } = await httpClient.get<UnbxdResult>('autosuggest', {
      params,
    });

    const products =
      response?.products.map((product): UnbxdProduct => {
        return {
          doctype: product.doctype,
          autosuggest: product.autosuggest,
          result_unbxd_double: product.result_unbxd_double,
          ...transformProduct(product),
        };
      }) ?? [];

    return {
      requestId,
      response: {
        numberOfProducts: response?.numberOfProducts ?? 0,
        start: response?.start ?? 0,
        products,
      },
      facets,
      searchMetaData,
    };
  }

  async recommendations(options: UnbxdRecommendationsOptions): Promise<UnbxdRecommendationsResult> {
    const params: UnbxdRecommendationsRequestParams = {
      ...options,
      ip: '0.0.0.0',
      uid: typeof window !== 'undefined' ? Cookies.get(UNBXD_USERID_COOKIE_NAME) ?? '' : '',
      fields: productFields.join(','),
    };

    const {
      data: { widget1, widget2, widget3 },
      headers: { 'x-request-id': requestId },
    } = await httpClient.get<UnbxdRecommendationsResult>('items', {
      params,
      baseURL:
        'https://recommendations.unbxd.io/v2.0/' +
        process.env.NEXT_PUBLIC_UNBXD_API_KEY +
        '/' +
        process.env.NEXT_PUBLIC_UNBXD_SITE_KEY +
        '/',
    });

    const widgets = [];
    widget1 && widgets.push(widget1);
    widget2 && widgets.push(widget2);
    widget3 && widgets.push(widget3);

    for (const widget of widgets) {
      widget.recommendations =
        widget.recommendations.map((product): UnbxdProduct => {
          return transformProduct(product);
        }) ?? [];
    }

    return {
      requestId,
      widget1,
      widget2,
      widget3,
      widgets,
    };
  }

  pushEvent(event: UnbxdEvent) {
    dataLayerPush(event);
  }

  _dedupeOptions(options: UnbxdOptions): UnbxdRequestParams {
    return {
      ...(options.start && { start: options.start }),
      ...(options.page && { page: options.page }),
      ...(options.rows && { rows: options.rows }),
      ...(options.sort && options.sort != unbxdDefaultSorting && { sort: getSortingParam(options.sort) }),
      ...(options.filters && { filter: getFiltersParams(options.filters) }),
      uid: typeof window !== 'undefined' ? Cookies.get(UNBXD_USERID_COOKIE_NAME) ?? '' : '',
      'variants.count': 0,
      version: 'V2',
    };
  }
})();

const httpClient = axios.create({
  baseURL:
    'https://search.unbxd.io/' +
    process.env.NEXT_PUBLIC_UNBXD_API_KEY +
    '/' +
    process.env.NEXT_PUBLIC_UNBXD_SITE_KEY +
    '/',
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    indexes: null,
  },
});

setAxiosLogger(httpClient);
