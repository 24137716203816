import isEmpty from 'lodash/isEmpty';
import { unbxdDefaultSorting } from './unbxd/sorting';
import { UnbxdOptions } from './unbxd/types';

export const ROUTES = {
  HOME: '/',
  CHECKOUT: '/checkout',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  ACCOUNT: '/my-account',
  ORDERS: '/my-account/orders',
  ACCOUNT_DETAILS: '/my-account/details',
  SHOPS: '/shops',
  SEARCH: '/search',
  COLLECTIONS: '/collections',
  CATEGORY: '/category',
  ORDER: '/order',
  DESIGN_MY_HOME: '/design-my-home',
};

export function getOrderRoute(number: string, hash: string, type?: 'success') {
  return ROUTES.ORDER + '/' + number + '/' + hash + (type ? '?' + type : '');
}

export function getProductRoute(urlKey: string) {
  if (urlKey.startsWith('http:') || urlKey.startsWith('https:')) {
    try {
      const urlObj = new URL(urlKey);
      urlKey = urlObj.pathname.substring(1);
    } catch { }
  }

  urlKey = urlKey.replace('.html', '');

  return '/p/' + urlKey;
}

export function getSearchRoute(query: string, options?: ProductsOptions) {
  return getProductListRoute(['search', query], options);
}

export function getCategoryRoute(urlKey: string, options?: ProductsOptions) {
  return getProductListRoute(['c', urlKey], options);
}

export function getProductListRoute(paths: string[], options?: ProductsOptions) {
  paths = paths.map((path) => encodeURIPath(path));
  const optionsParam = options && getProductsOptionsRouteParam(options);
  return '/' + paths.join('/') + (optionsParam?.toString() ? '/' + optionsParam.toString() : '');
}

export function encodeURIPath(path: string) {
  return encodeURIComponent(path).replace(/%20/g, '+').replace(/%2F/g, '/');
}

export function decodeURIPart(path: string) {
  return decodeURIComponent(path).replace(/\+/g, ' ');
}

export interface ProductsOptions {
  page?: UnbxdOptions['page'];
  sort?: UnbxdOptions['sort'];
  filters?: UnbxdOptions['filters'];
}

export function getProductsOptionsRouteParam(options: ProductsOptions) {
  const params = new URLSearchParams();

  options.sort && options.sort != unbxdDefaultSorting && params.set('sort', options.sort.toString());

  if (options.filters) {
    for (const key in options.filters) {
      options.filters[key]?.map((value) => {
        params.append(key, value.replace('&', '%26').replace('/', '%252F'));
      });
    }
  }

  return params;
}

const nonFilterParams = ['sort'];

export function parseProductsOptionsRouteParam(param?: string): ProductsOptions {
  const params = new URLSearchParams(param);

  const filters: UnbxdOptions['filters'] = {};

  for (const key of params.keys()) {
    if (key in nonFilterParams) {
      continue;
    }

    filters[key] = params.getAll(key);
  }

  return {
    sort: (params.get('sort') as UnbxdOptions['sort']) ?? unbxdDefaultSorting,
    ...(!isEmpty(filters) && { filters }),
  };
}
