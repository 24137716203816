// @ts-check

const { stores, storeCode } = require('./src/utils/stores.js');

/** @type {Record<typeof stores[number], string>} */
const localesByStore = {
  'de-de': 'de',
  'ch-de': 'de',
  ch_fr: 'ch-fr',
};

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    locales: [localesByStore[storeCode]], // define the locales in @/utils/i18next.tsx too
    defaultLocale: localesByStore[storeCode],
    localeDetection: false,
  },
  localePath: './src/locales',
};
