const CaretLeftIcon = ({ color = 'currentColor', width = '18px', height = '18px', className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className={className}
    >
      <polygon
        fill={color}
        points="17.74,23.94 17.74,0.06 6.26,12 "
      />
    </svg>
  );
};

export default CaretLeftIcon;
