import { useSsrCompatible } from '@/utils/use-ssr-compatible';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useWindowSize } from 'react-use';
import Carousel from '../ui/carousel';

interface UspsProps {
  className?: string;
}

export default function Usps(props: UspsProps) {
  const { t } = useTranslation('common');
  const { width } = useSsrCompatible(useWindowSize(), { width: 1024, height: 0 });
  const headerTexts = [t('header-text-1'), t('header-text-2'), t('header-text-3')];

  return (
    <>
      {width < 1024 ? (
        <Carousel
          slidesPerView={1}
          infinite={true}
          autoplay={{
            delay: 4000,
          }}
          effect={'slide'}
          initialSlide={1}
          className={classNames('w-full pt-3 xl:pt-4', props.className)}
        >
          {headerTexts.map((text, idx) => (
            <div
              key={idx}
              className="text-center"
            >
              {text}
            </div>
          ))}
        </Carousel>
      ) : (
        <div
          className={classNames(
            'flex w-full items-center justify-center pt-3 lg:justify-between lg:px-6 2xl:px-16',
            props.className,
          )}
        >
          {headerTexts.map((text, idx) => (
            <div
              key={idx}
              className="h-auto overflow-auto text-center first:hidden last:hidden lg:w-1/3 lg:first:block lg:first:text-left lg:last:block lg:last:text-right"
            >
              {text}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
