import { getSearchRoute } from '@/utils/routes';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';
import { useRouter } from 'next/router';
import { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { useLocalStorage } from 'react-use';

const maxLastSearches = 5;

export interface SearchContextData {
  value: string;
  setValue: (value: string) => void;
  focused: boolean;
  setFocused: (focused: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  submit: (value?: string) => void;
  lastSearches?: string[];
  addLastSearch: (search: string) => void;
}

export const SearchContext = createContext<SearchContextData>({
  value: '',
  setValue: () => {},
  focused: false,
  setFocused: () => {},
  loading: false,
  setLoading: () => {},
  submit: () => {},
  lastSearches: [],
  addLastSearch: () => {},
});

export function SearchProvider(props: PropsWithChildren) {
  const [value, setValue] = useState<SearchContextData['value']>('');
  const [focused, setFocused] = useState<SearchContextData['focused']>(false);
  const [loading, setLoading] = useState<SearchContextData['loading']>(false);
  const [lastSearches, setLastSearches] = useLocalStorage<string[]>('lastSearches', []);
  const router = useRouter();

  function addLastSearch(search: string) {
    if (trim(search).length == 0) {
      return;
    }

    if (trim(search) == '*') {
      return;
    }

    let searches = lastSearches ?? [];

    searches.unshift(trim(search));
    searches = uniq(searches);

    if (searches && searches.length > maxLastSearches) {
      searches.pop();
    }

    setLastSearches(searches);
  }

  return (
    <SearchContext.Provider
      value={{
        value,
        setValue,
        focused,
        setFocused,
        loading,
        setLoading,
        lastSearches,
        addLastSearch: useCallback(addLastSearch, [setLastSearches, lastSearches]),
        submit: async (val?: string) => {
          val = val ?? value;

          if (trim(val).length == 0) {
            return;
          }

          setValue(trim(val));
          addLastSearch(val);

          setLoading(true);
          await router.push(getSearchRoute(trim(val)));
          setLoading(false);
        },
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
}
