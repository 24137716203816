import { useSearch } from '@/hooks/search/useSearch';
import { UnbxdProduct } from '@/utils/unbxd/types';
import Link from '@components/ui/link';
import { getProductRoute } from '@utils/routes';
import Image from '../common/image';
import Money from '../common/money';
import Highlight from './highlight';

interface SearchProductProps {
  item: UnbxdProduct;
  onClick?: () => void;
}

export default function SearchProduct(props: SearchProductProps) {
  const { item: product } = props;
  const { value } = useSearch();

  return (
    <Link
      href={getProductRoute(product.productUrl as string)}
      className="group flex h-auto w-full items-center justify-start py-3 xl:w-1/2 3xl:w-1/3"
      onClick={props.onClick}
      onAuxClick={(e) => {
        if (e.button === 1) {
          props.onClick?.();
        }
      }}
    >
      <div className="relative flex h-20 w-20 flex-shrink-0 cursor-pointer overflow-hidden bg-gray-200 me-4">
        <Image
          key={`${product.title}`}
          src={product.imageUrl?.[0]}
          width={80}
          height={80}
          loading="eager"
          hasLoader={true}
          alt={`${product.title}`}
          className="bg-gray-200 object-contain"
        />
      </div>
      <div className="flex w-full flex-col overflow-hidden">
        <div className="mb-2 text-sm uppercase text-heading line-clamp-3 group-hover:underline">
          <Highlight
            text={product.title as string}
            search={[value]}
          />
        </div>
        <div className="flex items-center text-sm text-heading">
          {product.regularPrice && Number(product.regularPrice) > Number(product.finalPrice) && (
            <del className="order-2 ml-2 text-xs font-normal text-gray-800">
              <Money
                money={{
                  value: product.regularPrice,
                }}
              />
            </del>
          )}
          <div>
            <Money
              money={{
                value: product.finalPrice,
              }}
            />
          </div>
        </div>
      </div>
    </Link>
  );
}
