import { CmsPageQuery } from '.gql/graphql';
import { CategoryListQuery } from '@/graphql/category/CategoryList.gql';
import { CmsBlockFragment } from '@/graphql/cms/Blocks.gql';
import { StoreConfigFragment } from '@/graphql/store/StoreConfig.gql';
import { storeEmailAddressesConfigQuery } from '@/graphql/store/StoreEmailAdressesConfig.gql';
import { CrispChatBoxQuery } from '@/graphql/common/CrispChatBox.gql';
import { TrustedShopQuery } from '@/graphql/common/TrustedShop.gql';

import { createContext, ReactNode } from 'react';

export type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export interface AppContextProps {
  storeConfig: Required<RequiredNotNull<StoreConfigFragment>> & {
    emails?: NonNullable<storeEmailAddressesConfigQuery['StoreEmailAddressesConfig']> | null;
  };
  crispChatBoxData: CrispChatBoxQuery['crispChatBox'] | null;
  trustedShopData: TrustedShopQuery['trustedShop'] | null;
  categories?: NonNullable<CategoryListQuery['categories']>['items'];
  categoriesHash?: string;
  headerPromotionBlock?: CmsBlockFragment | null;
  clientErrorPage?: CmsPageQuery['cmsPage'] | null;
  unbxdProductsCount: number;
}

const defaultProps: AppContextProps = {
  storeConfig: {
    __typename: 'StoreConfig',
    store_code: '',
    store_name: '',
    website_name: '',
    default_display_currency_code: '',
    cms_home_page: '',
    default_title: '',
    product_url_suffix: '',
    category_url_suffix: '',
    root_category_uid: '',
    default_description: '',
    secure_base_url: '',
    store_information_config: {
      store_name: '',
      store_phone_number: '',
      store_hours_of_operation: '',
      store_country: '',
      store_region: '',
      store_zip_code: '',
      store_city: '',
      store_street_address_one: '',
      store_street_address_two: '',
      store_vat_number: '',
      country_options_config: {
        default_country_code: '',
        default_country_name: '',
      },
    },
  },
  crispChatBoxData: {
    website_id: null,
  },
  trustedShopData: {
    enabled: true,
    ts_id: null,
    color_scheme: 'light',
    disable_trustbadge: false,
    desktop_enable_custom: false,
    desktop_position: 'right',
    desktop_y_offset: '-20',
    desktop_custom_width: '156',
    desktop_disable_reviews: false,
    desktop_enable_fadeout: false,
    disable_mobile: false,
    mobile_enable_custom: false,
    mobile_position: 'left',
    mobile_y_offset: '0',
    mobile_custom_width: '156',
    mobile_disable_reviews: false,
    mobile_enable_fadeout: true,
    mobile_enable_topbar: false,
  },
  unbxdProductsCount: 45000,
};

export const AppContext = createContext<AppContextProps>({
  ...defaultProps,
});

export function AppProvider(props: { props: AppContextProps | undefined; children: ReactNode }) {
  if (!props.props) {
    props = {
      props: {
        ...defaultProps,
      },
      children: props.children,
    };
  }
  return <AppContext.Provider value={{ ...props.props! }}>{props.children}</AppContext.Provider>;
}
