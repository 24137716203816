import { CategoryListQuery } from '@/graphql/category/CategoryList.gql';
import { useApp } from '@/hooks/app/useApp';
import { useSearch } from '@/hooks/search/useSearch';
import { getCategoryRoute } from '@/utils/routes';
import { trpc } from '@/utils/trpc';
import Scrollbar from '@components/common/scrollbar';
import Link from '@components/ui/link';
import { useUI } from '@contexts/ui.context';
import classNames from 'classnames';
import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

export default function MobileMenu() {
  const { categories, categoriesHash } = useApp();
  const { data } = trpc.category.list.useQuery(categoriesHash);
  const [activeMenus, setActiveMenus] = useState<any>([]);
  const { closeSidebar } = useUI();
  const { focused: searchFocused } = useSearch();
  const handleArrowClick = (menuName: string) => {
    let newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuName)) {
      var index = newActiveMenus.indexOf(menuName);
      if (index > -1) {
        newActiveMenus.splice(index, 1);
      }
    } else {
      newActiveMenus.push(menuName);
    }

    setActiveMenus(newActiveMenus);
  };

  const ListMenu = ({
    dept,
    item,
    menuName,
    menuIndex,
    className = '',
  }: {
    dept: number;
    item: NonNullable<NonNullable<CategoryListQuery['categories']>['items']>[number];
    menuName: string;
    menuIndex: number;
    className?: string | boolean;
  }) => (
    <li className={`mb-0.5 ${className}`}>
      <div className="relative flex items-center justify-between">
        <Link
          href={getCategoryRoute(item?.url_key as string)}
          className="menu-item relative w-full py-3 text-[15px] transition duration-300 ease-in-out pe-4 ps-5 md:ps-6"
        >
          <span
            className={classNames('block w-full', { 'text-gf-red': item?.url_key == 'sale' })}
            onClick={closeSidebar}
          >
            {item?.name}
          </span>
        </Link>
        {Number(item?.children?.length) > 0 && (
          <div
            className="flex h-full w-full cursor-pointer items-center justify-end text-lg pe-5 start-0"
            onClick={() => handleArrowClick(menuName)}
          >
            <IoIosArrowDown
              className={`transform text-heading transition duration-200 ease-in-out ${
                activeMenus.includes(menuName) ? '-rotate-180' : 'rotate-0'
              }`}
            />
          </div>
        )}
      </div>
      {Number(item?.children?.length) > 0 && (
        <SubMenu
          dept={dept}
          data={item?.children}
          toggle={activeMenus.includes(menuName)}
          menuIndex={menuIndex}
        />
      )}
    </li>
  );

  const SubMenu = ({ dept, data, toggle, menuIndex }: any) => {
    if (!toggle) {
      return null;
    }

    dept = dept + 1;

    return (
      <ul className="pt-0.5">
        {data?.map((menu: any, index: number) => {
          const menuName: string = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

          return (
            <ListMenu
              dept={dept}
              item={menu}
              menuName={menuName}
              key={menuName}
              menuIndex={index}
              className={dept > 1 && 'ps-4'}
            />
          );
        })}
      </ul>
    );
  };

  return (
    <>
      {!searchFocused && (
        <Scrollbar>
          <div className="flex flex-col py-4 px-0 text-heading lg:px-2">
            <ul className="mobileMenu">
              {(data ?? categories)?.map((menu, index) => {
                const dept: number = 1;
                const menuName: string = `sidebar-menu-${dept}-${index}`;

                return (
                  <ListMenu
                    dept={dept}
                    item={menu}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                  />
                );
              })}
            </ul>
          </div>
        </Scrollbar>
      )}
    </>
  );
}
