import { useStore } from '@/hooks/store/useStore';
import { useRouter } from 'next/router';
import { MoneyFragment } from './money.gql';

interface MoneyProps {
  money?: MoneyFragment | null;
  locale?: string;
  extra?: number;
}

export function getCurrencyFormat(locale: string | undefined, currency: string) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });
}

export default function Money(props: MoneyProps) {
  const router = useRouter();
  const store = useStore();

  return (
    <>
      {getCurrencyFormat(
        props.locale || router.locale,
        props.money?.currency?.toString() ?? store.default_display_currency_code,
      ).format((props.money?.value ?? 0) + (props.extra ?? 0))}
    </>
  );
}
