import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { InputHTMLAttributes } from 'react';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  labelKey?: string;
  placeholderKey?: string;
  name: string;
  errorKey?: string;
  type?: string;
  shadow?: boolean;
  variant?: 'normal' | 'solid' | 'outline';
}
const classes = {
  root: 'py-2 px-4 md:px-3 rounded-none w-full appearance-none transition duration-150 ease-in-out border text-input disabled:bg-gray-300 text-xs lg:text-sm font-body placeholder-placeholder min-h-12 transition duration-200 ease-in-out',
  normal: 'bg-gray-100 border-gray-300 focus:shadow focus:bg-white focus:border-primary',
  solid: 'bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12',
  outline: 'border-gray-300 focus:border-primary focus:outline-none focus:border-heading',
  shadow: 'focus:shadow',
};
const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className = 'block',
      labelKey,
      name,
      errorKey,
      placeholderKey,
      variant = 'normal',
      shadow = false,
      type = 'text',
      inputClassName,
      ...rest
    },
    ref,
  ) => {
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === 'normal',
        [classes.solid]: variant === 'solid',
        [classes.outline]: variant === 'outline',
      },
      {
        [classes.shadow]: shadow,
      },
      inputClassName,
    );
    const { t } = useTranslation();
    return (
      <div className={className}>
        {labelKey && (
          <label
            htmlFor={name}
            className="mb-3 block text-sm font-semibold leading-none text-gray-600"
          >
            {t(labelKey)}
          </label>
        )}
        <input
          id={name}
          name={name}
          type={type}
          ref={ref}
          // @ts-ignore
          placeholder={t(placeholderKey)}
          className={rootClassName}
          autoComplete="off"
          spellCheck="false"
          aria-invalid={errorKey ? 'true' : 'false'}
          {...rest}
        />
        {errorKey && <p className="my-2 text-xs text-red-500">{t(errorKey)}</p>}
      </div>
    );
  },
);

export default Input;
