import { useUI } from '@/contexts/ui.context';
import { useApp } from '@/hooks/app/useApp';
import { useSearch } from '@/hooks/search/useSearch';
import SearchIcon from '@components/icons/search-icon';
import cn from 'classnames';
import trim from 'lodash/trim';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { getNumberFormat } from '../common/number';

interface SearchInputProps {
  className?: string;
}

export default function SearchInput(props: SearchInputProps) {
  const { t } = useTranslation('forms');
  const { value, loading, submit, setValue, setFocused } = useSearch();
  const { closeSidebar } = useUI();
  const { unbxdProductsCount } = useApp();
  const inputRef = useRef<HTMLInputElement>(null);
  const { locale } = useRouter();

  useEffect(() => {
    function clickEvent() {
      if (document.activeElement != inputRef.current) {
        setFocused(false);
      }
    }

    function blurEvent() {
      setFocused(false);
    }

    document.addEventListener('click', clickEvent);
    window.addEventListener('blur', blurEvent);

    return () => {
      document.removeEventListener('click', clickEvent);
      window.removeEventListener('blur', blurEvent);
    };
  }, [setFocused]);

  function onSubmit() {
    if (trim(value).length == 0) {
      return;
    }

    submit();
    inputRef.current?.blur();
    setFocused(false);
    closeSidebar();
  }

  return (
    <form
      className={cn('relative w-full bg-borderBottom lg:overflow-hidden', props.className)}
      noValidate
      role="search"
      onSubmit={(el) => {
        el.preventDefault();
        onSubmit();
      }}
    >
      <label
        htmlFor="search"
        className="flex items-center"
      >
        <input
          id="search"
          className="h-14 w-full border border-transparent bg-transparent text-base text-heading placeholder-gray-400 outline-none ps-5 pe-14 focus:border-gray-600 focus:bg-white"
          placeholder={t('placeholder-search', { products: getNumberFormat(locale).format(unbxdProductsCount) })}
          aria-label="Search"
          autoComplete="off"
          value={value}
          onChange={(input) => setValue(input.target.value)}
          onFocus={() => setFocused(true)}
          ref={inputRef}
        />
        <span
          className="absolute top-0 right-0 flex h-full w-12 flex-shrink-0 cursor-pointer items-center justify-center text-icon hover:text-action focus:outline-none md:w-14"
          onClick={(el) => {
            el.preventDefault();
            onSubmit();
          }}
        >
          {loading ? (
            <CgSpinner
              className="animate-spin"
              size={24}
            />
          ) : (
            <SearchIcon className="h-[18px] w-[18px]" />
          )}
        </span>
      </label>
    </form>
  );
}
