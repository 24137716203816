import PageBuilder from '@/components/pagebuilder/pageBuilder';
import { trpc } from '@/utils/trpc';
import Container from '@components/ui/container';
import Link from '@components/ui/link';
import { storeCode } from '@/utils/stores';
import { getSiteSettings } from '@settings/site-settings';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { AiOutlineArrowUp } from 'react-icons/ai';

interface CopyrightProps {
  variant?: 'contemporary';
}
const year = new Date().getFullYear();
const Copyright: React.FC<CopyrightProps> = ({ variant }) => {
  const { t } = useTranslation('footer');
  const { data } = trpc.cmsBlocks.get.useQuery({
    identifiers: ['footer-payment-icons'],
  });

  return (
    <div className="border-t border-gray-300 py-5">
      <Container
        className={cn('flex flex-col-reverse items-center text-center md:flex-row md:justify-between', {
          'items-center': variant === 'contemporary',
        })}
      >
        <p
          className={cn('text-xs leading-6 text-body lg:text-sm', {
            'm-0 p-0': variant === 'contemporary',
          })}
        >
          {t('text-copyright')} &copy; {year}&nbsp;
          <a
            className="font-semibold text-gray-700 transition-colors duration-200 ease-in-out hover:text-action"
            href={getSiteSettings(storeCode).author.websiteUrl}
          >
            {getSiteSettings(storeCode).author.name}
          </a>
          &nbsp; <span className="whitespace-nowrap">{t('text-all-rights-reserved')}</span>
        </p>

        {data?.[0] && (
          <div className="mb-4 lg:mb-0 [&_img]:mx-2">
            <PageBuilder
              pagebuilder={data[0]?.pagebuilder}
              noWrapper={true}
            />
          </div>
        )}

        {variant === 'contemporary' && (
          <p className="cursor-pointer text-sm font-semibold leading-[19px] text-[#212121]">
            <Link href="#siteHeader">Scroll to top</Link>

            <AiOutlineArrowUp className="inline ms-3" />
          </p>
        )}
      </Container>
    </div>
  );
};

export default Copyright;
