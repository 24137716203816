import { CmsPageQuery } from '.gql/graphql';
import { Component, PropsWithChildren, ReactNode } from 'react';
import PageBuilder from '../pagebuilder/pageBuilder';

interface ErrorBoundaryState {
  hasError: boolean;
}
interface ErrorBoundaryProps extends PropsWithChildren {
  clientErrorPage: CmsPageQuery['cmsPage'] | null | undefined;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  render(): ReactNode {
    if (this.state.hasError) {
      if (!this.props.clientErrorPage?.pagebuilder) {
        return <DefaultErrorComponent />;
      }

      try {
        return (
          <>
            <PageBuilder
              pagebuilder={this.props.clientErrorPage?.pagebuilder}
              noWrapper={true}
            />
          </>
        );
      } catch (e) {
        return <DefaultErrorComponent />;
      }
    }

    return this.props.children;
  }
}

function DefaultErrorComponent() {
  return <div>An error occurred on client</div>;
}

export default ErrorBoundary;
