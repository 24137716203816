const UserIcon = ({ color = 'currentColor', width = '18px', height = '20px', loggedIn = false, className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      {loggedIn ? (
        <g>
          <circle
            fill={color}
            cx="12"
            cy="5.74"
            r="5.74"
          />
          <g>
            <path
              fill={color}
              d="M12,17.86l4.71-4.51c-1.32-0.53-2.75-0.82-4.25-0.82h-0.92c-1.5,0-2.94,0.3-4.25,0.82L12,17.86z"
            />
            <path
              fill={color}
              d="M18.59,14.31l-6.57,6.29L12,20.63l0,0l0,0l-0.03-0.03l-6.57-6.29C2.2,16.35,0.06,19.92,0.06,24h23.87
		C23.94,19.92,21.8,16.35,18.59,14.31z"
            />
          </g>
        </g>
      ) : (
        <g>
          <circle
            fill={color}
            cx="12"
            cy="5.74"
            r="5.74"
          />
          <path
            fill={color}
            d="M23.94,24L23.94,24L0.06,24v0c0-6.34,5.14-11.48,11.48-11.48h0.92C18.8,12.52,23.94,17.66,23.94,24z"
          />
        </g>
      )}
    </svg>
  );
};

export default UserIcon;
