import ProductItem from '@/components/products/product';
import Carousel from '@/components/ui/carousel';
import BoxLoader from '@/components/ui/loaders/box-loader';
import { unbxdClient } from '@/utils/unbxd';
import { UnbxdRecommendationsRequestParams, UnbxdRecommendationsResult } from '@/utils/unbxd/types';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useEffect } from 'react';
import { parseSliderOptions } from '../Slider/slider';

export interface UnbxdHtmlWidget {
  pageType: UnbxdRecommendationsRequestParams['pageType'];
  data?: UnbxdRecommendationsResult;
  container: 'widget1' | 'widget2' | 'widget3';
  id?: string;
  cssClasses?: string[];
  className?: string;
  title?: React.ReactNode;
}

export default function Recommendations(props: UnbxdHtmlWidget) {
  const cssClasses = [...(props.cssClasses ?? [])];

  const {
    data: recsData,
    error,
    isLoading,
  } = useQuery({
    queryFn: async () =>
      unbxdClient.recommendations({
        pageType: props.pageType,
        id: props.id,
        widget: props.container.toUpperCase() as UnbxdRecommendationsRequestParams['widget'],
      }),
    queryKey: ['recs' + props.pageType + ';' + props.container + ';' + props.id],
    retry: 1,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: !props.data,
  });

  const data = props.data || recsData;
  const widgets = props.data?.widgets || recsData?.widgets;
  const sliderOptions = parseSliderOptions(cssClasses);

  useEffect(() => {
    if (!data?.requestId) {
      return;
    }

    widgets?.map((widget) => {
      if (widget.recommendations.length == 0) {
        return;
      }

      unbxdClient.pushEvent({
        ExperienceImpressionPayload: null,
      });

      unbxdClient.pushEvent({
        event: 'ExperienceImpression',
        ExperienceImpressionPayload: {
          requestId: data?.requestId as string,
          pids_list: widget.recommendations.map((item) => item.uniqueId),
          experience_pagetype: props.pageType,
          experience_widget: props.container.toUpperCase() as UnbxdRecommendationsRequestParams['widget'],
        },
      });
    });
  }, [data?.requestId, props.container, props.pageType]);

  if (error) {
    return null;
  }

  if (!isLoading && (!widgets || widgets.length == 0)) {
    return null;
  }

  return (
    <div className={classNames('overflow-hidden', props.className)}>
      {props.title}
      {!widgets && (
        <Carousel
          infinite={true}
          arrows={true}
          slidesPerView={sliderOptions.slides}
          className="space-x-2"
          breakpoints={
            sliderOptions.slidesBreakpoints ?? {
              0: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              960: {
                slidesPerView: 4,
              },
              1280: {
                slidesPerView: 5,
              },
              1600: {
                slidesPerView: 6,
              },
            }
          }
        >
          {Array.from({ length: 5 }).map((_, idx) => (
            <BoxLoader
              key={idx}
              className="h-[340px] px-2"
            />
          ))}
        </Carousel>
      )}
      {widgets
        ?.filter((widget) => widget.recommendations.length > 0)
        .map((widget, idx) => (
          <Carousel
            key={idx}
            infinite={true}
            arrows={true}
            slidesPerView={sliderOptions.slides}
            breakpoints={
              sliderOptions.slidesBreakpoints ?? {
                0: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 3,
                },
                960: {
                  slidesPerView: 4,
                },
                1280: {
                  slidesPerView: 5,
                },
                1600: {
                  slidesPerView: 6,
                },
              }
            }
          >
            {widget.recommendations.map((item) => (
              <ProductItem
                key={idx}
                idx={idx}
                product={item}
                className="mx-4"
                recommendation={{
                  requestId: data?.requestId as string,
                  experience_pagetype: props.pageType,
                  experience_widget: props.container.toUpperCase() as UnbxdRecommendationsRequestParams['widget'],
                }}
              />
            ))}
          </Carousel>
        ))}
    </div>
  );
}
