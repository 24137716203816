const SearchIcon = ({ color = 'currentColor', width = '17px', height = '18px', className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill={color}
        d="M23.71,22.29l-6.58-6.58c1.35-1.66,2.16-3.77,2.16-6.07C19.28,4.32,14.97,0,9.64,0C4.32,0,0,4.32,0,9.64
	c0,5.32,4.32,9.64,9.64,9.64c2.3,0,4.41-0.81,6.07-2.16l6.58,6.58C22.49,23.9,22.74,24,23,24s0.51-0.1,0.71-0.29
	C24.1,23.32,24.1,22.68,23.71,22.29z"
      />
    </svg>
  );
};

export default SearchIcon;
