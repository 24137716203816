import classNames from 'classnames';
import { arrayOf, bool, number, object, oneOf, shape, string } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useStyle } from '../../classify';
import resourceUrl from '../../makeUrl';
import { useMediaQuery } from '../../useMediaQuery';
import { verticalAlignmentToFlex } from '../../utils';
import defaultClasses from './row.module.css';

const { matchMedia } = globalThis;

const Row = (props) => {
  const backgroundElement = useRef(null);
  const [bgImageStyle, setBgImageStyle] = useState(null);
  const classes = useStyle(defaultClasses, props.classes);

  const {
    appearance,
    verticalAlignment,
    minHeight,
    backgroundColor,
    desktopImage,
    mobileImage,
    backgroundSize,
    backgroundPosition,
    backgroundAttachment,
    backgroundRepeat,
    enableParallax,
    parallaxSpeed = 0.5,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    mediaQueries,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    children,
    cssClasses = [],
    backgroundType,
    videoSrc,
    videoFallbackSrc,
    videoLoop,
    videoPlayOnlyVisible,
    videoLazyLoading,
    videoOverlayColor,
  } = props;

  const { styles: mediaQueryStyles } = useMediaQuery({ mediaQueries });

  let image = desktopImage;
  if (mobileImage && matchMedia && matchMedia('(max-width: 768px)').matches) {
    image = mobileImage;
  }

  const dynamicStyles = {
    minHeight,
    backgroundColor,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };

  const videoOverlayStyles = {
    backgroundColor: videoOverlayColor,
  };

  if (image && bgImageStyle) {
    dynamicStyles.backgroundImage = bgImageStyle;
    dynamicStyles.backgroundSize = backgroundSize;
    dynamicStyles.backgroundPosition = backgroundPosition;
    dynamicStyles.backgroundAttachment = backgroundAttachment;
    dynamicStyles.backgroundRepeat = backgroundRepeat;
  }

  if (verticalAlignment) {
    dynamicStyles.display = 'flex';
    dynamicStyles.justifyContent = verticalAlignmentToFlex(verticalAlignment);
    dynamicStyles.flexDirection = 'column';
  }
  //
  // Determine the containers width and optimize the image
  useEffect(() => {
    // Intelligently resize cover background images
    if (image && backgroundElement.current) {
      if (backgroundSize === 'cover') {
        let elementWidth = backgroundElement.current.offsetWidth;
        let elementHeight = backgroundElement.current.offsetHeight;
        // If parallax is enabled resize at a higher resolution, as the image will be zoomed
        if (enableParallax) {
          elementWidth = Math.round(elementWidth * 1.25);
          elementHeight = Math.round(elementHeight * 1.25);
        }
        setBgImageStyle(
          `url(${resourceUrl(image, {
            type: 'image-wysiwyg',
            width: elementWidth,
            height: elementHeight,
            quality: 85,
            crop: false,
            fit: 'cover',
          })})`,
        );
      } else {
        setBgImageStyle(
          `url(${resourceUrl(image, {
            type: 'image-wysiwyg',
            quality: 85,
          })})`,
        );
      }
    }
  }, [backgroundSize, enableParallax, image, setBgImageStyle]);

  const videoOverlay = videoOverlayColor ? (
    <div
      className={classes.videoOverlay}
      style={videoOverlayStyles}
    />
  ) : null;

  if (appearance === 'full-bleed') {
    return (
      <div
        ref={backgroundElement}
        style={{
          ...dynamicStyles,
          ...mediaQueryStyles,
          marginLeft: null,
          marginRight: null,
        }}
        className={[classes.fullBleed, classes.root, ...cssClasses].join(' ')}
      >
        {videoOverlay}
        {children}
      </div>
    );
  }

  if (appearance === 'full-width') {
    return (
      <div
        ref={backgroundElement}
        style={{
          ...dynamicStyles,
          ...mediaQueryStyles,
          marginLeft: null,
          marginRight: null,
        }}
        className={[classes.fullBleed, classes.root, ...cssClasses].join(' ')}
      >
        {videoOverlay}
        <div className={classNames(classes.contained, 'px-4 md:px-8 lg:px-6 2xl:px-16')}>{children}</div>
      </div>
    );
  }

  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {});

  let dynamicStyles2 = Object.filter(dynamicStyles, (style) => style != '');

  return (
    <div className={[classes.contained, ...cssClasses, 'px-4 md:px-8 lg:px-6 2xl:px-16'].join(' ')}>
      <div
        ref={backgroundElement}
        className={classes.inner}
        style={{ ...dynamicStyles2, ...mediaQueryStyles }}
      >
        {videoOverlay}
        {children}
      </div>
    </div>
  );
};

/**
 * Props for {@link Row}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the Row
 * @property {String} classes.contained CSS class for the contained appearance element
 * @property {String} classes.inner CSS class for the inner appearance element
 * @property {String} classes.root CSS class for the row root element
 * @property {String} classes.videoOverlay CSS class for the videoOverlay element
 * @property {String} minHeight CSS minimum height property
 * @property {String} backgroundColor CSS background-color property
 * @property {String} desktopImage Background image URL to be displayed on desktop devices
 * @property {String} mobileImage Background image URL to be displayed on mobile devices
 * @property {String} backgroundSize CSS background-size property
 * @property {String} backgroundPosition CSS background-position property
 * @property {String} backgroundAttachment CSS background-attachment property
 * @property {String} backgroundRepeat CSS background-repeat property
 * @property {Boolean} enableParallax Enable parallax on this row
 * @property {Number} parallaxSpeed The speed which Parallax should scroll, from -1.0 to 2.0
 * @property {String} textAlign Alignment of content within the row
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {Array} mediaQueries List of media query rules to be applied to the component
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 * @property {String} backgroundType Background type
 * @property {String} videoSrc URL to the video
 * @property {String} videoFallbackSrc URL to the image which will be displayed before video
 * @property {Boolean} videoLoop Play video in loop
 * @property {Boolean} videoPlayOnlyVisible Play video when it is visible
 * @property {Boolean} videoLazyLoading Load video when it is visible
 * @property {String} videoOverlayColor Color for video overlay
 */
Row.propTypes = {
  classes: shape({
    root: string,
    contained: string,
    fullBleed: string,
    inner: string,
    videoOverlay: string,
  }),
  appearance: oneOf(['contained', 'full-width', 'full-bleed']),
  verticalAlignment: oneOf(['top', 'middle', 'bottom']),
  minHeight: string,
  backgroundColor: string,
  desktopImage: string,
  mobileImage: string,
  backgroundSize: string,
  backgroundPosition: string,
  backgroundAttachment: string,
  backgroundRepeat: string,
  enableParallax: bool,
  parallaxSpeed: number,
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  mediaQueries: arrayOf(
    shape({
      media: string,
      style: object,
    }),
  ),
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  paddingLeft: string,
  cssClasses: arrayOf(string),
  backgroundType: string,
  videoSrc: string,
  videoFallbackSrc: string,
  videoLoop: bool,
  videoPlayOnlyVisible: bool,
  videoLazyLoading: bool,
  videoOverlayColor: string,
};

export default Row;
