import React, { useEffect } from 'react';
import { useApp } from '@/hooks/app/useApp';

const TrustedShop: React.FC = () => {
  const { trustedShopData } = useApp();

  useEffect(() => {
    if (!trustedShopData?.enabled) {
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = `//widgets.trustedshops.com/js/${trustedShopData.ts_id}.js`;

    script.setAttribute('data-color-scheme', trustedShopData.color_scheme);
    script.setAttribute('data-disable-trustbadge', trustedShopData.disable_trustbadge.toString());
    script.setAttribute('data-desktop-enable-custom', trustedShopData.desktop_enable_custom.toString());
    script.setAttribute('data-desktop-position', trustedShopData.desktop_position);
    script.setAttribute('data-desktop-y-offset', trustedShopData.desktop_y_offset);
    script.setAttribute('data-desktop-custom-width', trustedShopData.desktop_custom_width);
    script.setAttribute('data-desktop-disable-reviews', trustedShopData.desktop_disable_reviews.toString());
    script.setAttribute('data-desktop-enable-fadeout', trustedShopData.desktop_enable_fadeout.toString());
    script.setAttribute('data-disable-mobile', trustedShopData.disable_mobile.toString());
    script.setAttribute('data-mobile-enable-custom', trustedShopData.mobile_enable_custom.toString());
    script.setAttribute('data-mobile-position', trustedShopData.mobile_position);
    script.setAttribute('data-mobile-y-offset', trustedShopData.mobile_y_offset);
    script.setAttribute('data-mobile-custom-width', trustedShopData.mobile_custom_width);
    script.setAttribute('data-mobile-disable-reviews', trustedShopData.mobile_disable_reviews.toString());
    script.setAttribute('data-mobile-enable-fadeout', trustedShopData.mobile_enable_fadeout.toString());
    script.setAttribute('data-mobile-enable-topbar', trustedShopData.mobile_enable_topbar.toString());

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [trustedShopData]);

  return null;
};

export default TrustedShop;
