import SearchInput from '@/components/search/input';
import SearchSuggestions from '@/components/search/suggestions';
import Logo from '@/components/ui/logo';
import { SearchProvider } from '@/contexts/search';
import { useUI } from '@/contexts/ui.context';
import { IoClose } from 'react-icons/io5';
import MobileMenu from './mobile-menu';

export default function Sidebar() {
  const { closeSidebar } = useUI();

  return (
    <div className="flex h-full w-full flex-col">
      <div className="relative flex w-full flex-shrink-0 items-center justify-between border-b border-gray-100 py-0.5 ps-5 md:ps-7">
        <Logo className="w-[150px]" />

        <button
          className="flex items-center justify-center px-4 py-4 text-2xl text-gray-500 transition-opacity hover:opacity-60 focus:outline-none md:px-6 lg:py-8"
          onClick={closeSidebar}
          aria-label="close"
        >
          <IoClose className="mt-1 text-black md:mt-0.5" />
        </button>
      </div>
      <SearchProvider>
        <SearchInput />
        <SearchSuggestions />
        <MobileMenu />
      </SearchProvider>
    </div>
  );
}
