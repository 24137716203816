import { gaClient, unbxdProductToGaItem } from '@/utils/ga';
import { ProductsOptions, getCategoryRoute, getSearchRoute } from '@/utils/routes';
import { unbxdClient } from '@/utils/unbxd';
import { UnbxdFacets, UnbxdFilters, UnbxdProduct, UnbxdResult, UnbxdSorting } from '@/utils/unbxd/types';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { useProductListMemory } from '../../hooks/products/useProductListMemory';

export const PRODUCT_LIST_ROWS = 20;

export interface ProductListContextData {
  type: 'browse' | 'search';
  urlKey: string;
  fetchKey: string;
  unbxdPage?: string;
  requestId: string;
  numberOfProducts: number;
  products: UnbxdProduct[];
  facets: UnbxdFacets | undefined;
  options: ProductsOptions;
  nextPage: () => Promise<void>;
  setSort: (sort: UnbxdSorting) => void;
  setFilters: (filters: UnbxdFilters | undefined) => void;
}

export const ProductListContext = createContext<ProductListContextData>({
  type: 'browse',
  urlKey: '',
  fetchKey: '',
  unbxdPage: undefined,
  requestId: '',
  numberOfProducts: 0,
  products: [],
  facets: undefined,
  options: {
    page: 1,
  },
  nextPage: async () => {},
  setSort: () => {},
  setFilters: () => {},
});

export function ProductListProvider(props: {
  data: Omit<ProductListContextData, 'nextPage' | 'setSort' | 'setFilters'>;
  children: ReactNode;
}) {
  const { getMemoryData, setMemoryData } = useProductListMemory();
  const router = useRouter();

  const memoryData = getMemoryData(router.asPath);

  const [requestId, setRequestId] = useState(memoryData?.['requestId'] ?? props.data.requestId);
  const [options, setOptions] = useState(memoryData?.['options'] ?? props.data.options);
  const [products, setProducts] = useState(memoryData?.['products'] ?? props.data.products);
  const [facets, setFacets] = useState(memoryData?.['facets'] ?? props.data.facets);
  const [numberOfProducts, setNumberOfProducts] = useState(
    memoryData?.['numberOfProducts'] ?? props.data.numberOfProducts,
  );

  function addProducts(products: UnbxdProduct[]) {
    setProducts((prevProducts) => prevProducts.concat(products));
  }

  function sendUnbxdEvents(data: { requestId: UnbxdResult['requestId']; products: UnbxdProduct[] }) {
    unbxdClient.pushEvent(
      props.data.type == 'browse'
        ? {
            BrowseImpressionPayload: null,
          }
        : {
            SearchImpressionPayload: null,
          },
    );

    unbxdClient.pushEvent(
      props.data.type == 'browse'
        ? {
            event: 'BrowseImpression',
            BrowseImpressionPayload: {
              requestId: data.requestId,
              page: 'categoryPath:"' + props.data.unbxdPage + '"',
              page_type: 'BOOLEAN',
              pids_list: data.products.map((product) => product.uniqueId),
            },
          }
        : {
            event: 'SearchImpression',
            SearchImpressionPayload: {
              requestId: data.requestId,
              query: props.data.fetchKey,
              pids_list: data.products.map((product) => product.uniqueId),
            },
          },
    );

    gaClient.pushEvent({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: props.data.type,
        item_list_name: props.data.type == 'browse' ? props.data.unbxdPage : props.data.fetchKey,
        items:
          data.products.map((item) => ({
            ...unbxdProductToGaItem(item),
          })) ?? [],
      },
    });

    if (!isEmpty(options.filters)) {
      unbxdClient.pushEvent({
        FacetPayload: null,
      });

      unbxdClient.pushEvent(
        props.data.type == 'browse'
          ? {
              event: 'UnbxdFacet',
              FacetPayload: {
                requestId: data.requestId,
                page: 'categoryPath:"' + props.data.unbxdPage + '"',
                page_type: 'BOOLEAN',
                facets: options.filters,
              },
            }
          : {
              event: 'UnbxdFacet',
              FacetPayload: {
                requestId: data.requestId,
                query: props.data.fetchKey,
                facets: options.filters,
              },
            },
      );
    }
  }

  async function fetchProducts(opts: Partial<ProductsOptions>) {
    const data =
      props.data.type == 'browse'
        ? await unbxdClient.browse({
            categoryId: props.data.fetchKey,
            rows: PRODUCT_LIST_ROWS,
            ...options,
            ...opts,
          })
        : await unbxdClient.search({
            query: props.data.fetchKey,
            rows: PRODUCT_LIST_ROWS,
            ...options,
            ...opts,
          });

    sendUnbxdEvents({
      requestId: data.requestId,
      products: data.response.products,
    });

    return data;
  }

  useEffect(() => {
    const url =
      props.data.type == 'browse'
        ? getCategoryRoute(props.data.urlKey, options)
        : getSearchRoute(props.data.urlKey, options);

    if (router.asPath != url + location.search) {
      router.replace(url + location.search, undefined, {
        shallow: true,
      });
    }
  }, [router, props.data.urlKey, props.data.type, options]);

  useEffect(() => {
    unbxdClient.pushEvent(
      props.data.type == 'browse'
        ? {
            event: 'CategoryPage',
            CategoryPagePayload: {
              requestId: requestId,
              page: 'categoryPath:"' + props.data.unbxdPage + '"',
              page_type: 'BOOLEAN',
            },
          }
        : {
            event: 'SearchQuery',
            SearchQueryPayload: {
              requestId: requestId,
              query: props.data.fetchKey,
            },
          },
    );

    sendUnbxdEvents({
      requestId: props.data.requestId,
      products: props.data.products,
    });
  }, [props.data.fetchKey, props.data.type]);

  useEffect(() => {
    if (router.query.redirectFrom) {
      unbxdClient.pushEvent({
        SearchQueryPayload: null,
      });

      unbxdClient.pushEvent({
        event: 'SearchQuery',
        SearchQueryPayload: {
          requestId: requestId,
          query: router.query.redirectFrom as string,
        },
      });
    }
  }, [router.query.redirectFrom]);

  useEffect(() => {
    setMemoryData(
      {
        requestId,
        numberOfProducts,
        products,
        options,
        facets,
      },
      router.asPath,
    );
  }, [products, requestId, numberOfProducts, options, setMemoryData, router.asPath]);

  return (
    <ProductListContext.Provider
      value={{
        ...props.data,
        numberOfProducts,
        products,
        options,
        facets,

        async nextPage() {
          const data = await fetchProducts({
            page: (options.page ?? 1) + 1,
          });

          setRequestId(data.requestId);
          addProducts(data.response.products);
          setOptions((prevOptions) => {
            return {
              ...prevOptions,
              page: (prevOptions.page ?? 1) + 1,
            };
          });
        },

        async setSort(sort: UnbxdSorting) {
          setOptions((prevOptions) => {
            return {
              ...prevOptions,
              page: 1,
              sort,
            };
          });

          const data = await fetchProducts({
            page: 1,
            sort,
          });

          setRequestId(data.requestId);
          setProducts(data.response.products);
          setNumberOfProducts(data.response.numberOfProducts);
        },

        async setFilters(filters: UnbxdFilters | undefined) {
          setOptions((prevOptions) => {
            return {
              ...prevOptions,
              page: 1,
              filters,
            };
          });

          const data = await fetchProducts({
            page: 1,
            filters,
          });

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          setRequestId(data.requestId);
          setProducts(data.response.products);
          setNumberOfProducts(data.response.numberOfProducts);
        },
      }}
    >
      {props.children}
    </ProductListContext.Provider>
  );
}
