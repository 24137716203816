import { useHydrated } from '@/hooks/utils/useHydrated';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { createContext, PropsWithChildren, useEffect } from 'react';
import { sendConsent } from './cookiesConsent';

export interface DataLayerContextData {
  push: (data?: any) => void;
}

export const DataLayerContext = createContext<DataLayerContextData>({
  push: dataLayerPush,
});

export function DataLayerProvider(props: PropsWithChildren) {
  const router = useRouter();
  const hydrated = useHydrated();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  return (
    <DataLayerContext.Provider
      value={{
        push: dataLayerPush,
      }}
    >
      {process.env.NEXT_PUBLIC_GTM_ID && hydrated && (
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
          `,
          }}
        />
      )}
      {props.children}
    </DataLayerContext.Provider>
  );
}

export function dataLayerPush(data: any) {
  if (!window.dataLayer) {
    window.dataLayer = [];
    sendConsent('default', {
      analytics_storage: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
    });
    window.dataLayer.push({
      UnbxdApiKey: process.env.NEXT_PUBLIC_UNBXD_API_KEY,
      UnbxdSiteKey: process.env.NEXT_PUBLIC_UNBXD_SITE_KEY,
    });
  }

  window.dataLayer?.push(data);

  window.dispatchEvent(
    new CustomEvent('datalayerpush', {
      detail: data,
    }),
  );
}

const pageview = (url: string) => {
  dataLayerPush({
    event: 'pageview',
    page: url,
  });
};

declare const window: Window & { dataLayer: Record<string, unknown>[] };
