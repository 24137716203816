import classNames from 'classnames';
import { CSSProperties, ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import SwipperClass, { Autoplay, Navigation, Pagination, Scrollbar, SwiperOptions, Thumbs } from 'swiper';
import 'swiper/css';
//import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AutoplayOptions } from 'swiper/types';
import CaretLeftIcon from '../icons/caret-left';
import CaretRightIcon from '../icons/caret-right';

interface NavigateButtonProps {
  type: 'prev' | 'next';
}

const NavigateButton = forwardRef<HTMLButtonElement, NavigateButtonProps>((props: NavigateButtonProps, ref) => (
  <button
    className={classNames(
      'absolute top-0 -mt-6 flex h-12 w-12 transform items-center justify-center rounded-full text-icon transition hover:text-action focus:outline-none',
      { 'start-0': props.type == 'prev' },
      { 'end-0': props.type == 'next' },
    )}
    aria-label="next-button"
    ref={ref}
  >
    {props.type === 'next' ? <CaretRightIcon /> : <CaretLeftIcon />}
  </button>
));

interface CarouselProps {
  className?: string;
  enabled?: boolean;
  effect?: SwiperOptions['effect'];
  infinite?: boolean;
  dots?: SwiperOptions['pagination'];
  arrows?: boolean;
  autoplay?: AutoplayOptions | boolean;
  initialSlide?: number;
  style?: CSSProperties;
  children?: React.ReactNode[];
  slidesPerView?: SwiperOptions['slidesPerView'];
  centeredSlides?: boolean;
  breakpoints?: SwiperOptions['breakpoints'];
  onSlideChange?: (currentSlide: number) => void;
  thumbsRef?: any;
  threshold?: number;
}

export default forwardRef<SwipperClass, CarouselProps>((props: CarouselProps, ref: ForwardedRef<SwipperClass>) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [swiper, setSwiper] = useState<SwipperClass>();

  useEffect(() => {
    if (!swiper) {
      return;
    }

    if (typeof swiper.navigation == 'object' && prevRef.current && nextRef.current) {
      swiper.navigation.prevEl = prevRef.current;
      swiper.navigation.nextEl = nextRef.current;
    }
  }, [props.initialSlide, swiper]);

  return (
    <div className={classNames('carouselWrapper relative', props.className)}>
      <Swiper
        style={{ ...props.style }}
        enabled={props.enabled}
        threshold={props.threshold ?? 5}
        modules={[Navigation, Pagination, Autoplay, Scrollbar, Thumbs]}
        pagination={props.dots}
        centeredSlides={props.centeredSlides ?? true}
        breakpoints={props.breakpoints}
        autoplay={props.autoplay}
        effect={props.effect}
        centerInsufficientSlides={true}
        loop={props.infinite}
        slidesPerView={props.slidesPerView ?? 'auto'}
        navigation={
          props.arrows && {
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }
        }
        speed={1000}
        className={classNames({ 'pb-10': props.dots })}
        initialSlide={props.initialSlide ?? 0}
        onSwiper={(swiper) => {
          setSwiper(swiper);
          if (ref && 'current' in ref) {
            ref.current = swiper;
          }
        }}
        onSlideChange={(swiper) => {
          props.onSlideChange?.(swiper.activeIndex);
        }}
        thumbs={{ swiper: props.thumbsRef && !props.thumbsRef.destroyed ? props.thumbsRef : null }}
      >
        {props.children?.map((item, index: number) => (
          <SwiperSlide key={`product-gallery-key-${index}`}>{item}</SwiperSlide>
        ))}
      </Swiper>
      {props.arrows && (
        <div className="swiper-navigation absolute top-2/4 z-10 flex w-full items-center">
          <NavigateButton
            type="prev"
            ref={prevRef}
          />
          <NavigateButton
            type="next"
            ref={nextRef}
          />
        </div>
      )}
    </div>
  );
});
