import { Pagebuilder } from '.gql/graphql';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ConditionalWrapper from '../common/conditionalWrapper';
import ContentTypeFactory from './factory';

interface PageBuilderProps {
  pagebuilder: Pagebuilder | null | undefined;
  noWrapper?: boolean;
  loader?: React.ReactNode;
}

export default function PageBuilder(props: PageBuilderProps) {
  const [isLoaded, setLoaded] = useState(!props.pagebuilder?.html);

  useEffect(() => setLoaded(true), []);

  if (!props.pagebuilder) {
    return <></>;
  }

  return (
    <ConditionalWrapper
      condition={!props.noWrapper}
      wrapper={(children) => (
        <div className={classNames('cms-content transition-opacity', { 'opacity-0': !isLoaded && !props.loader })}>
          {!isLoaded && props.loader}
          {children}
        </div>
      )}
    >
      {!isLoaded || !props.pagebuilder?.components ? (
        <div
          dangerouslySetInnerHTML={{ __html: props.pagebuilder?.html ?? '' }}
        ></div>
      ) : (
        props.pagebuilder?.components?.children.map((child: any, i: number) => {
          return (
            <ContentTypeFactory
              key={i}
              data={child}
            />
          );
        })
      )}
    </ConditionalWrapper>
  );
}
