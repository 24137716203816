import { useId } from 'react';
import ContentLoader from 'react-content-loader';

const BoxLoader = (props: any) => {
  const id = useId();

  return (
    <ContentLoader
      speed={1}
      width={'100%'}
      height={'100%'}
      viewBox="0 0 100 100"
      backgroundColor="#f3f3f3"
      foregroundColor="#eaeaea"
      gradientRatio={1}
      preserveAspectRatio={'none'}
      title={''}
      uniqueKey={id}
      {...props}
    >
      <rect
        x="0"
        y="0"
        width="100"
        height="100"
      />
    </ContentLoader>
  );
};

export default BoxLoader;
