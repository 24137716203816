import { AxiosConsoleLogger } from '@freewall/axios-console-logger';
import { AxiosInstance } from 'axios';

export const axiosLogger = process.env.NODE_ENV === 'development' ? new AxiosConsoleLogger() : undefined;

export function setAxiosLogger(instance: AxiosInstance) {
  if (axiosLogger) {
    instance.interceptors.request.use((request) => axiosLogger.request(request));
    instance.interceptors.response.use(
      (response) => axiosLogger.response(response),
      (error) => axiosLogger.error(error),
    );
  }
}
