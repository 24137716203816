import Image from '@/components/common/image';
import Link from '@/components/ui/link';
import { CategoryListQuery } from '@/graphql/category/CategoryList.gql';
import { getCategoryRoute } from '@/utils/routes';

interface BrandsProps {
  item: NonNullable<NonNullable<CategoryListQuery['categories']>['items']>[number];
  onLinkClick: () => void;
}

export default function Brands(props: BrandsProps) {
  const { item } = props;

  return (
    <div className="w-2/3 space-y-4 bg-gray-200 py-10 px-12">
      {item?.children
        ?.filter((child): child is Exclude<typeof child, null | undefined> => !!child && !!child.image)
        ?.map((child, idx) => (
          <div
            key={idx}
            className="inline-block w-1/3 px-2"
          >
            <Link
              key={idx}
              href={getCategoryRoute(child?.url_key as string)}
              className="w-full"
              onClick={props.onLinkClick}
            >
              <Image
                className="h-[20px] w-auto"
                src={child.image as string}
                alt={child.name as string}
                width={140}
                height={60}
              />
            </Link>
          </div>
        ))}
    </div>
  );
}
