import cn from 'classnames';
import { ButtonHTMLAttributes, forwardRef } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: 'main' | 'secondary' | 'link' | 'main-right';
  active?: boolean;
  type?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  disabled?: boolean;
  disableBorderRadius?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, variant = 'main', children, active, loading = false, disabled = false, ...rest } = props;

  return (
    <button
      aria-pressed={active}
      data-variant={variant}
      ref={ref}
      className={cn(
        'button inline-flex cursor-pointer items-center justify-center border-0 border-transparent px-7 text-center font-body text-base font-semibold uppercase placeholder-white transition duration-300 ease-in-out focus:outline-none focus-visible:outline-none md:text-base',
        {
          'py-2.5 text-white': variant === 'main' || variant == 'main-right',
          'py-2.5 text-heading': variant === 'secondary',
          'py-2 text-heading underline': variant === 'link',
          'loading cursor-not-allowed': loading,
          'cursor-not-allowed hover:cursor-not-allowed': disabled,
        },
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
      {loading && (
        <svg
          className="h-5 w-5 animate-spin text-white -me-1 ms-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
    </button>
  );
});

export default Button;
