import { ProductDetailFragment } from '@/graphql/route/ProductDetail.gql';
import { getPercentDiscount } from '@/utils/product';
import { UnbxdProduct } from '@/utils/unbxd/types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { PropsWithChildren } from 'react';

const NEW_PRODUCT_DAYS = 90;

interface BadgesProps {
  place: 'grid' | 'detail';
  product: ProductDetailFragment | UnbxdProduct;
  className?: string;
  badgeCutOut?: string;
}

function Badge({
  children,
  place,
  className,
  badgeCutOut,
}: PropsWithChildren & { place: BadgesProps['place']; className?: string; badgeCutOut?: string }) {
  return (
    <div
      className={classNames('discount-badge w-max font-semibold', className, badgeCutOut, {
        'py-1 px-5 pr-3.5 md:pl-2 md:pr-8': place == 'grid',
        'py-1.5 pr-5 md:py-2 md:pl-3 md:pr-10': place == 'detail',
      })}
    >
      {children}
    </div>
  );
}

function isUnbxdProduct(product: ProductDetailFragment | UnbxdProduct): product is UnbxdProduct {
  return typeof (product as UnbxdProduct).uniqueId !== 'undefined';
}

export default function Badges(props: BadgesProps) {
  const { product } = props;
  const { t } = useTranslation('common');

  const discount = isUnbxdProduct(product)
    ? getPercentDiscount(product.regularPrice, product.finalPrice)
    : getPercentDiscount(
        product.price_range.minimum_price.regular_price.value,
        product.price_range.minimum_price.final_price.value,
      );

  const isProductNew = isUnbxdProduct(product)
    ? product.createdAt
      ? new Date(product.createdAt).getTime() > new Date().setDate(new Date().getDate() - NEW_PRODUCT_DAYS)
      : false
    : product.created_at
    ? new Date(product.created_at).getTime() > new Date().setDate(new Date().getDate() - NEW_PRODUCT_DAYS)
    : false;

  const isAvailableImmediately = isUnbxdProduct(product)
    ? Number(product.qty) > 0
    : Number(product.stock_info?.qty_real_available) > 0;

  return (
    <div
      className={classNames('absolute left-0 z-10', props.className, {
        'top-0 space-y-1': props.place == 'grid',
        'top-8 space-y-1.5 md:space-y-2': props.place == 'detail',
      })}
    >
      {isAvailableImmediately && (
        <Badge
          place={props.place}
          className="bg-[#326e2b] text-white"
          badgeCutOut="[clip-path:polygon(100%_0%,_88%_51%,_100%_100%,_0%_100%,_0%_0%)]"
        >
          <div
            className={classNames('text-white', {
              'text-sm': props.place == 'grid',
              'text-base md:text-lg': props.place == 'detail',
            })}
          >
            {t('text-badge-available-immediately')}
          </div>
        </Badge>
      )}
      {!!discount && (
        <Badge
          place={props.place}
          className="bg-black text-white"
          badgeCutOut="[clip-path:polygon(100%_0%,_78%_51%,_100%_100%,_0%_100%,_0%_0%)]"
        >
          <div
            className={classNames('text-white', {
              'text-sm': props.place == 'grid',
              'text-base md:text-lg': props.place == 'detail',
            })}
          >
            {discount > 5 ? '-' + discount + '%' : t('text-badge-sale')}
          </div>
        </Badge>
      )}
      {isProductNew && (
        <Badge
          place={props.place}
          className="bg-[#e89e44] text-white"
          badgeCutOut="[clip-path:polygon(100%_0%,_78%_51%,_100%_100%,_0%_100%,_0%_0%)]"
        >
          <div
            className={classNames('text-white', {
              'text-sm': props.place == 'grid',
              'text-base md:text-lg': props.place == 'detail',
            })}
          >
            {t('text-badge-new')}
          </div>
        </Badge>
      )}
    </div>
  );
}
