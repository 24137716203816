import { useCart } from '@/hooks/cart/useCart';
import { useCartQuery } from '@/hooks/cart/useCartQuery';
import { useCustomer } from '@/hooks/customer/useCustomer';
import { apolloClient } from '@/utils/apollo';
import Cookies from 'js-cookie';
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { CartDetailDocument, CartDetailFragment } from './cart.gql';

const CART_COOKIE_NAME = 'cart';

export interface CartContextData {
  cartId?: string;
  setCartId: (cartId?: string) => void;
  cart?: CartDetailFragment;
  setCart: (cart: CartDetailFragment) => void;
  cartExists?: boolean;
  cartIsEmpty: boolean;
  cartIsValid: boolean;
  setCartExists: (exists: boolean) => void;
  destroyCart: () => void;
}

export const CartContext = createContext<CartContextData>({
  cartId: undefined,
  setCartId: () => {},
  cart: undefined,
  setCart: () => {},
  cartExists: undefined,
  cartIsEmpty: true,
  cartIsValid: false,
  setCartExists: () => {},
  destroyCart: () => {},
});

export function CartProvider(props: PropsWithChildren) {
  const [cartId, setCartId] = useState<CartContextData['cartId']>();
  const [cart, setCart] = useState<CartContextData['cart']>();
  const [exists, setExists] = useState<CartContextData['cartExists']>();

  return (
    <CartContext.Provider
      value={{
        cartId,
        setCartId: useCallback(
          (cartId?: string) => {
            setCartId(cartId);

            if (!cartId) {
              Cookies.remove(CART_COOKIE_NAME);
              return;
            }

            Cookies.set(CART_COOKIE_NAME, cartId);
          },
          [setCartId],
        ),
        cart,
        setCart: useCallback(
          (cart) => {
            apolloClient.cache.updateQuery(
              { query: CartDetailDocument, variables: { cartId: cartId as string, init: true } },
              () => {
                return {
                  cart,
                };
              },
            );

            setCart(cart);
            setExists(!!cart);
          },
          [setCart, cartId],
        ),
        cartExists: exists,
        cartIsEmpty: !cart || cart.total_quantity < 1,
        cartIsValid: !!(exists && cart?.shipping_addresses),
        setCartExists: useCallback(
          (exists) => {
            setExists(exists);
          },
          [setExists],
        ),
        destroyCart: useCallback(() => {
          setCart(undefined);
          setCartId(undefined);
          setExists(false);
          Cookies.remove(CART_COOKIE_NAME);
        }, [setCartId, setCart]),
      }}
    >
      <CartConsumer />
      {props.children}
    </CartContext.Provider>
  );
}

function CartConsumer() {
  const { cartId: customerCartId, customerExists } = useCustomer();
  const { cartId, setCart, setCartId, setCartExists, destroyCart } = useCart();

  const { data, error } = useCartQuery(CartDetailDocument, {
    errorPolicy: 'none',
  });

  useEffect(() => {
    if (customerExists === undefined) {
      return;
    }

    if (customerCartId) {
      (async () => {})();
      setCartId(customerCartId);
      Cookies.remove(CART_COOKIE_NAME);

      return;
    }

    const cart = Cookies.get(CART_COOKIE_NAME);
    cart && setCartId(cart);
    if (!cart) {
      setCartExists(false);
    }
  }, [cartId, setCartId, customerCartId, customerExists, setCartExists]);

  useEffect(() => {
    if (
      error?.message.startsWith('Could not find a cart with ID') ||
      error?.message.startsWith('Ein Warenkorb mit der ID') ||
      error?.message.startsWith("The cart isn't active") ||
      error?.message.startsWith('The current use cannot perform operations on cart')
    ) {
      destroyCart();
      return;
    }

    if (data?.cart) {
      setCart(data?.cart);
    }

    if (data) {
      setCartExists(!!data?.cart);
    }
  }, [cartId, error, data, setCart, setCartExists, destroyCart]);

  return <></>;
}
