const CartIcon = ({
  color = 'currentColor',
  width = '18px',
  height = '18px',
  className = 'md:w-4 xl:w-5 md:h-4 xl:h-5',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill={color}
        d="M22.02,9.11c-0.49-5.07-4.78-9.06-9.98-9.06c-5.2,0-9.49,3.98-9.98,9.06H0V24h24V9.11H22.02z M12.04,2.06
	c4.1,0,7.48,3.09,7.96,7.06H4.08C4.56,5.14,7.95,2.06,12.04,2.06z"
      />
    </svg>
  );
};

export default CartIcon;
