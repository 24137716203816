//import React from 'react';
import Banner from './ContentTypes/Banner';
import Block from './ContentTypes/Block/block';
import ButtonItem from './ContentTypes/ButtonItem';
import Buttons from './ContentTypes/Buttons';
import Column from './ContentTypes/Column';
import ColumnGroup from './ContentTypes/ColumnGroup';
import ColumnLine from './ContentTypes/ColumnLine';
import Divider from './ContentTypes/Divider';
import Heading from './ContentTypes/Heading';
import Html from './ContentTypes/Html';
import Image from './ContentTypes/Image';
import Products from './ContentTypes/Products';
import Row from './ContentTypes/Row';
import Slider from './ContentTypes/Slider';
import Text from './ContentTypes/Text';

const contentTypesConfig: {
  [key: string]: {
    component: React.ElementType;
  };
} = {
  row: {
    component: Row,
  },
  column: {
    component: Column,
  },
  'column-group': {
    component: ColumnGroup,
  },
  'column-line': {
    component: ColumnLine,
  },
  image: {
    component: Image,
  },
  divider: {
    component: Divider,
  },
  heading: {
    component: Heading,
  },
  html: {
    component: Html,
  },
  text: {
    component: Text,
  },
  buttons: {
    component: Buttons,
  },
  'button-item': {
    component: ButtonItem,
  },
  banner: {
    component: Banner,
  },
  slider: {
    //component: React.lazy(() => import('./ContentTypes/Slider')),
    component: Slider,
  },
  slide: {
    component: Banner,
  },
  block: {
    component: Block,
  },
  products: {
    component: Products,
  },
};

export function getContentTypeConfig(contentType: string) {
  if (contentTypesConfig[contentType]) {
    return contentTypesConfig[contentType];
  }
}
