import React from 'react';

interface ConditionalWrapperProps extends React.PropsWithChildren {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
}

export default function ConditionalWrapper({ condition, wrapper, children }: ConditionalWrapperProps) {
  return condition ? wrapper(children) : <>{children}</>;
}
