import { useStore } from '@/hooks/store/useStore';
import { storeCode } from '@/utils/stores';
import { getSiteSettings } from '@settings/site-settings';
import { DefaultSeo as NextDefaultSeo } from 'next-seo';

export const DefaultSeo = () => {
  const store = useStore();

  return (
    <NextDefaultSeo
      title={store.default_title}
      description={getSiteSettings(storeCode).description}
      openGraph={{
        type: 'website',
        locale: 'en_IE',
        site_name: getSiteSettings(storeCode).name,
      }}
      twitter={{
        handle: '@handle',
        site: '@site',
        cardType: 'summary_large_image',
      }}
      additionalMetaTags={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
      ]}
      additionalLinkTags={
        [
          /* {
          rel: 'manifest',
          href: '/manifest.json',
        }, */
        ]
      }
    />
  );
};
