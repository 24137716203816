import Image from '@/components/common/image';
import Scrollbar from '@/components/common/scrollbar';
import CaretRightIcon from '@/components/icons/caret-right';
import Link from '@/components/ui/link';
import { CategoryListQuery } from '@/graphql/category/CategoryList.gql';
import { brandCategoryUrlKey, popularBrandsCategoryUrlKey } from '@/pages/c/marken';
import { getCategoryRoute } from '@/utils/routes';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Brands from './brands';

let hoverTimeout = 0;

interface SubmenuProps {
  item: NonNullable<NonNullable<CategoryListQuery['categories']>['items']>[number];
  active: boolean;
  last: boolean;
  onLinkClick: () => void;
  onMouseOver: () => void;
}

export default function Submenu({ item, active, last, onLinkClick, onMouseOver }: SubmenuProps) {
  const [activeChild, setActiveChild] = useState<string | undefined>(item?.children?.[0]?.uid ?? undefined);

  function onMouseOverLink(uid: string) {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      hoverTimeout = 0;
    }

    hoverTimeout = window.setTimeout(() => {
      hoverTimeout = 0;
      setActiveChild(uid);
    }, 150);
  }

  useEffect(() => {
    if (!activeChild) {
      setActiveChild(item?.children?.[0]?.uid);
    }
  }, [setActiveChild, activeChild, item?.children]);

  const hasChildren = item?.children?.find((child) => child?.children?.length) || item?.url_key == brandCategoryUrlKey;
  const popularBrandsCategory =
    item?.url_key == brandCategoryUrlKey
      ? item.children?.find((child) => child?.url_key == popularBrandsCategoryUrlKey)
      : null;

  return (
    <div
      className={classNames(
        'absolute flex max-h-64vh shadow-navigation',
        { hidden: !active },
        { 'w-full min-w-[800px]': hasChildren },
        { 'min-w-[320px]': !hasChildren },
        { '-translate-x-1/2 start-1/2': !(last && !popularBrandsCategory) && hasChildren },
        { 'end-0': last && !popularBrandsCategory },
      )}
      onMouseOver={onMouseOver}
    >
      <div
        className={classNames('bg-[#395978] py-10 text-white', {
          'w-full': !hasChildren,
          'w-1/3': hasChildren,
        })}
      >
        <Scrollbar>
          <div className="ml-12 mb-5 text-lg font-semibold uppercase">{item?.name}</div>
          <div>
            {item?.children
              ?.filter((child) => child?.url_key != popularBrandsCategoryUrlKey)
              .map((child, idx) => (
                <Link
                  key={idx}
                  href={getCategoryRoute(child?.url_key as string)}
                  className={classNames('flex items-center justify-between py-[10px] px-12 text-sm hover:underline', {
                    'bg-[#314D67]': activeChild == child?.uid,
                  })}
                  onClick={onLinkClick}
                  onMouseOver={() => onMouseOverLink(child?.uid as string)}
                >
                  <div>{child?.name}</div>
                  {Number(child?.children?.length) > 0 && (
                    <div className="-mr-2">
                      <CaretRightIcon
                        width="10px"
                        className="text-gray-200"
                      />
                    </div>
                  )}
                </Link>
              ))}
          </div>
        </Scrollbar>
      </div>
      {popularBrandsCategory ? (
        <Brands
          item={popularBrandsCategory}
          onLinkClick={onLinkClick}
        />
      ) : (
        hasChildren && (
          <div
            className="w-2/3 bg-gray-200 py-10 px-12"
            onMouseOver={() => {
              clearTimeout(hoverTimeout);
              hoverTimeout = 0;
            }}
          >
            {item?.children
              ?.filter((child) => Number(child?.children?.length) > 0)
              ?.filter((item): item is Exclude<typeof item, null | undefined> => !!item)
              .map((child, idx) => (
                <div
                  key={idx}
                  className={classNames('h-full', { hidden: activeChild != child.uid })}
                >
                  {child.image && (
                    <div className="absolute top-5 right-5 flex h-[120px] w-[120px] items-center rounded-full bg-gray-150 p-2">
                      <Image
                        src={child.image}
                        width={120}
                        className="pointer-events-none select-none"
                        alt={child.name as string}
                      />
                    </div>
                  )}
                  {!child.image && item.image && (
                    <div className="absolute top-5 right-5 flex h-[120px] w-[120px] items-center rounded-full bg-gray-150 p-2">
                      <Image
                        src={item.image}
                        width={120}
                        className="pointer-events-none absolute top-5 right-5 select-none"
                        alt={child.name as string}
                      />
                    </div>
                  )}
                  <Scrollbar
                    key={idx}
                    className={classNames({ hidden: activeChild != child.uid })}
                  >
                    <div className="mb-5 text-lg font-semibold">{child.name}</div>
                    <ul className="relative z-10 columns-2">
                      {child.children?.map((child, idx) => (
                        <Link
                          key={idx}
                          href={getCategoryRoute(child?.url_key as string)}
                          className="block py-[6px] text-sm hover:underline"
                          onClick={onLinkClick}
                        >
                          {child?.name}
                        </Link>
                      ))}
                    </ul>
                  </Scrollbar>
                </div>
              ))}
          </div>
        )
      )}
    </div>
  );
}
