import { useCustomer } from '@/hooks/customer/useCustomer';
import { useCustomerQuery } from '@/hooks/customer/useCustomerQuery';
import { apolloClient } from '@/utils/apollo';
import Cookies from 'js-cookie';
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { CustomerDetailDocument, CustomerDetailFragment } from './customer.gql';

export const CUSTOMER_COOKIE_NAME = 'customer';
const CUSTOMER_COOKIE_EXPIRE = 30;

export interface CustomerContextData {
  customerToken?: string;
  setCustomerToken: (customerToken?: string, remember?: boolean) => void;
  customer?: CustomerDetailFragment;
  setCustomer: (customer: CustomerDetailFragment) => void;
  customerExists?: boolean;
  setCustomerExists: (exists: boolean) => void;
  destroyCustomer: () => void;
  cartId?: string;
  setCartId: (cartId?: string) => void;
}

export const CustomerContext = createContext<CustomerContextData>({
  customerToken: undefined,
  setCustomerToken: () => {},
  customer: undefined,
  setCustomer: () => {},
  customerExists: undefined,
  setCustomerExists: () => {},
  destroyCustomer: () => {},
  cartId: undefined,
  setCartId: () => {},
});

export function CustomerProvider(props: PropsWithChildren) {
  const [customerToken, setCustomerToken] = useState<CustomerContextData['customerToken']>();
  const [customer, setCustomer] = useState<CustomerContextData['customer']>();
  const [cartId, setCartId] = useState<string>();
  const [exists, setExists] = useState<CustomerContextData['customerExists']>();

  useEffect(() => {
    const customer = Cookies.get(CUSTOMER_COOKIE_NAME);
    customer && setCustomerToken(customer);
    if (!customer) {
      setExists(false);
    }
  }, []);

  return (
    <CustomerContext.Provider
      value={{
        customerToken,
        setCustomerToken: useCallback(
          (customerToken?: string, remember?: boolean) => {
            setCustomerToken(customerToken);

            if (!customerToken) {
              Cookies.remove(CUSTOMER_COOKIE_NAME);
              return;
            }

            Cookies.set(CUSTOMER_COOKIE_NAME, customerToken, {
              expires: remember ? CUSTOMER_COOKIE_EXPIRE : undefined,
            });
          },
          [setCustomerToken],
        ),
        customer: customer,
        setCustomer: useCallback(
          (customer) => {
            apolloClient.cache.updateQuery({ query: CustomerDetailDocument }, (data) => {
              return {
                customer,
                customerCart: {
                  ...data?.customerCart,
                },
              };
            });

            setCustomer(customer);
          },
          [setCustomer /*, customerToken*/],
        ),
        customerExists: exists,
        setCustomerExists: useCallback(
          (exists) => {
            setExists(exists);
          },
          [setExists],
        ),
        destroyCustomer: useCallback(() => {
          setCustomer(undefined);
          setCustomerToken(undefined);
          setExists(false);
          Cookies.remove(CUSTOMER_COOKIE_NAME);
        }, [setCustomerToken, setCustomer]),
        cartId,
        setCartId,
      }}
    >
      <CustomerConsumer />
      {props.children}
    </CustomerContext.Provider>
  );
}

function CustomerConsumer() {
  const { customerToken, setCustomer, setCustomerExists, destroyCustomer, setCartId } = useCustomer();

  const { data, error } = useCustomerQuery(CustomerDetailDocument, {
    errorPolicy: 'none',
  });

  useEffect(() => {
    if (error) {
      destroyCustomer();
      return;
    }

    if (data?.customer) {
      setCustomer(data?.customer);
    }

    if (data?.customerCart) {
      setCartId(data.customerCart.id);
    }

    if (data) {
      setCustomerExists(!!data?.customer);
    }
  }, [customerToken, error, data, setCustomer, setCustomerExists, destroyCustomer, setCartId]);

  return <></>;
}
