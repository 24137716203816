import { ProductCustomAttributeFragment } from '@/graphql/product/ProductCustomAttribute.gql';
import { ProductDetailFragment } from '@/graphql/route/ProductDetail.gql';

export function getAttributeValue(attribute: ProductCustomAttributeFragment | undefined | null) {
  if (attribute?.__typename == 'ProductBooleanAttribute') {
    return attribute.boolean_value;
  }

  if (attribute?.__typename == 'ProductFloatAttribute') {
    return attribute.float_value;
  }

  if (attribute?.__typename == 'ProductIntAttribute') {
    return attribute.int_value;
  }

  if (attribute?.__typename == 'ProductStringAttribute') {
    return attribute.string_value;
  }

  return null;
}

interface AttributesProps {
  product: ProductDetailFragment;
}

export default function Attributes(props: AttributesProps) {
  const { product } = props;

  return (
    <table className="text-sm text-heading lg:text-base">
      <tbody>
        {product.all_custom_attributes
          ?.filter((attribute) => attribute?.attribute_label && getAttributeValue(attribute) !== null)
          .map((attribute, idx) => (
            <tr key={idx}>
              <td
                valign="top"
                className="py-1 font-semibold text-heading pe-4 lg:pe-8"
              >
                {attribute?.attribute_label}:
              </td>
              <td dangerouslySetInnerHTML={{ __html: getAttributeValue(attribute) as string }} />
            </tr>
          ))}
      </tbody>
    </table>
  );
}
