import Image from '@/components/common/image';
import Money from '@/components/common/money';
import Link from '@/components/ui/link';
import { useProductList } from '@/hooks/products/useProductList';
import { gaClient, unbxdProductToGaItem } from '@/utils/ga';
import { getProductRoute } from '@/utils/routes';
import { unbxdClient } from '@/utils/unbxd';
import { UnbxdProduct, UnbxdRecommendationsRequestParams } from '@/utils/unbxd/types';
import classNames from 'classnames';
import RatingDisplay from '../common/rating-display';
import Badges from '../product/productPageDetail/badges';

interface ProductItemProps {
  product: UnbxdProduct;
  idx: number;
  className?: string;
  recommendation?: {
    requestId: string;
    experience_pagetype: UnbxdRecommendationsRequestParams['pageType'];
    experience_widget: UnbxdRecommendationsRequestParams['widget'];
  };
}

export default function ProductItem(props: ProductItemProps) {
  const { product } = props;
  const { requestId, fetchKey, unbxdPage, type: listType } = useProductList();

  function onClick() {
    unbxdClient.pushEvent({
      ProductClickPayload: null,
    });

    if (props.recommendation) {
      unbxdClient.pushEvent({
        event: 'ProductClick',
        ProductClickPayload: {
          pid: product.uniqueId,
          prank: (props.idx + 1).toString(),
          ...props.recommendation,
        },
      });

      gaClient.pushEvent({
        event: 'select_item',
        ecommerce: {
          item_list_id: 'recs',
          item_list_name: props.recommendation.experience_pagetype,
          items: [unbxdProductToGaItem(product)],
        },
      });

      return;
    }

    if (listType) {
      unbxdClient.pushEvent(
        listType == 'browse'
          ? {
              event: 'ProductClick',
              ProductClickPayload: {
                requestId: requestId,
                pid: product.uniqueId,
                prank: (props.idx + 1).toString(),
                page: 'categoryPath:"' + unbxdPage + '"',
                page_type: 'BOOLEAN',
              },
            }
          : {
              event: 'ProductClick',
              ProductClickPayload: {
                requestId: requestId,
                pid: product.uniqueId,
                prank: (props.idx + 1).toString(),
                query: fetchKey,
              },
            },
      );

      gaClient.pushEvent({
        event: 'select_item',
        ecommerce: {
          item_list_id: listType,
          item_list_name: listType == 'browse' ? unbxdPage : fetchKey,
          items: [unbxdProductToGaItem(product)],
        },
      });
    }
  }

  return (
    <Link
      href={getProductRoute(product.productUrl as string)}
      className={classNames(`group box-border flex flex-col items-start overflow-hidden pe-0 md:pb-1`, props.className)}
      onClick={onClick}
      onAuxClick={(e) => {
        if (e.button === 1) {
          onClick();
        }
      }}
    >
      <div className={classNames('relative mb-3 flex w-full items-center justify-center pb-0 md:mb-3.5')}>
        <Badges
          place="grid"
          product={product}
        />
        <Image
          key={product.title}
          src={product.imageUrl?.[0]}
          alt={product.title as string}
          width={200}
          height={200}
          hasLoader={true}
          className="mb-4 h-[200px] w-[200px] transform object-contain transition duration-150 ease-linear group-hover:scale-105"
        />
      </div>
      <div className="w-full overflow-hidden py-2">
        <div className="mb-1 line-clamp-3 text-sm uppercase text-heading sm:text-base md:mb-1.5 md:text-sm lg:text-base xl:text-lg">
          {product.title}
        </div>
        {!!product.rating && (
          <RatingDisplay
            className="mb-0"
            rating={product.rating}
          />
        )}
        {/* {product.description && (
          <div
            className="max-w-[250px] truncate text-xs leading-normal text-body lg:text-sm xl:leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: product.description,
            }}
          ></div>
        )} */}
        <div className="mt-1.5 text-sm text-heading space-s-2 md:mt-2.5 md:text-base lg:text-xl 2xl:mt-3">
          <span className="inline-block">
            <Money
              money={{
                value: product.finalPrice,
              }}
            />
          </span>
          {product.regularPrice && Number(product.regularPrice) > Number(product.finalPrice) && (
            <del className="font-normal text-gray-800 sm:text-base">
              <Money
                money={{
                  value: product.regularPrice,
                }}
              />
            </del>
          )}
        </div>
      </div>
    </Link>
  );
}
