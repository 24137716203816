import { UnbxdSorting } from './types';

export const unbxdDefaultSorting: UnbxdSorting = 'bestseller';

export const unbxdSortingFields: { [key in UnbxdSorting]: string } = {
  bestseller: '',
  new: 'createdAt DESC',
  title: 'title ASC',
  price_asc: 'price ASC',
  price_desc: 'price DESC',
};

export function getSortingParam(sorting: UnbxdSorting) {
  return unbxdSortingFields[sorting];
}
